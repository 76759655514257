import { t } from 'i18next';
import * as yup from 'yup';

export const getPackageValidation = () => {
  const packageValidationSchema = yup.object({
    name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('packages.name')
      })
    ),
    type: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('packages.type')
      })
    ),
    unit: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('packages.unit')
      })
    ),
    weight_unit: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('packages.weight_unit')
      })
    ),
    is_default: yup.boolean(),
    width: yup.number().required(
      t('form.invalid.required', {
        fieldName: t('packages.width')
      })
    ),
    height: yup.number().required(
      t('form.invalid.required', {
        fieldName: t('packages.height')
      })
    ),
    length: yup.number().required(
      t('form.invalid.required', {
        fieldName: t('packages.length')
      })
    ),
    weight: yup.number().required(
      t('form.invalid.required', {
        fieldName: t('packages.weight')
      })
    )
  });

  return packageValidationSchema;
};
