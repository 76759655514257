import { useState, useEffect } from 'react';
import { percentageDifference } from 'helpers/utils';

/**
 * Function help find 10 products with highest color_count
 * @param {array} productsData array of products
 * @param {number} numTopProducts number top list
 * @returns
 */
export const topProductsByColor = (productsData, numTopProducts) => {
  const sortedProducts = productsData?.sort?.((a, b) => {
    const highestColorCountA = Math.max(
      ...a.colors.map(color => color.color_count)
    );
    const highestColorCountB = Math.max(
      ...b.colors.map(color => color.color_count)
    );
    return highestColorCountB - highestColorCountA;
  });

  return sortedProducts
    ?.slice(0, numTopProducts)
    .map(prod => ({
      id: prod._id,
      media_url: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      )?.prod_media[0]?.media_url,
      prod_name: prod.prod_name,
      color_name: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      ).color_name,
      sku: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      ).sku,
      color_count: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      ).color_count,
      prod_price: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      ).prod_price,
      color_uid: prod.colors.reduce((highest, curr) =>
        curr.color_count > highest ? curr : highest
      ).color_uid
    }))
    ?.filter(variant => variant.color_count > 0);
};

// Custom hook to wait for data is loaded before calculate percentage difference
export const usePercentageDifference = (value1, value2, isLoaded) => {
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      const newPercentage = percentageDifference(value1, value2);
      setPercentage(newPercentage);
    }
  }, [value1, value2, isLoaded]);

  return percentage;
};

export const getTotalPrice = items =>
  items
    ?.map(({ unit, price }) => unit * price)
    .reduce((total, currentValue) => total + currentValue, 0);

export const getTotalOrder = items =>
  items
    ?.map(({ unit }) => unit)
    .reduce((total, currentValue) => total + currentValue, 0);

export const imageUrlToId = url => {
  if (typeof url !== 'string') return url;
  const segments = url.split('/');
  const lastSegment = segments[segments.length - 1];
  return lastSegment;
};
