import IconFA from 'components/common/IconFA';
import NailzyImage from 'components/common/NailzyImage';
import CheckBox from 'components/common/checkbox';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { amountFormatter } from 'helpers/utils';
const oneLineStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  fontSize: 12
};
const ProductItem = ({
  product,
  onChange,
  selectedProducts = [],
  viewOnly = false
}) => {
  const [isExpand, setExpand] = useState(viewOnly);
  const foundProduct = useMemo(
    () => selectedProducts?.find(x => x?._id === product?._id),
    [selectedProducts, product]
  );
  const params = useParams();

  const onSelectVariant = (variant, checked) => {
    let data = foundProduct
      ? { ...foundProduct }
      : { ...product, variants: [] };
    data.variants = [...data?.variants]?.filter(
      x => x?.variant_id !== variant?.variant_id
    );

    if (checked) {
      data?.variants?.push({
        ...variant,
        discount_rate: variant?.price > 10 ? 10 : variant?.price,
        total_item:
          variant?.available_stock > 20 ? 20 : variant?.available_stock,
        item_per_order:
          variant?.available_stock > 2 ? 2 : variant?.available_stock
      });
    }
    if (!data.variants?.length) {
      onChange?.(data, false);
    } else {
      onChange?.({
        ...data,
        variants: [
          ...(data?.variants || [])?.map(x => ({
            ...x,
            discount_rate: variant?.price > 10 ? 10 : variant?.price,
            total_item:
              variant?.available_stock > 20 ? 20 : variant?.available_stock,
            item_per_order:
              variant?.available_stock > 2 ? 2 : variant?.available_stock
          }))
        ]
      });
    }
  };
  const renderVariant = (variant, index) => {
    const isVariantSelected =
      foundProduct &&
      foundProduct?.variants?.find(x => x?.variant_id === variant?.variant_id);
    const disabled = !variant?.available_stock;
    return (
      <div
        key={`variant_${index}`}
        onClick={() =>
          !disabled && onSelectVariant?.(variant, !isVariantSelected)
        }
        className={`d-flex flex-row clickable promotion-product-item${
          disabled ? '-disabled' : ''
        } py-2`}
        style={{}}
      >
        <Col lg={6}>
          <div className="d-flex flex-row align-items-center w-100 gap-2  ps-5">
            {viewOnly ? null : (
              <CheckBox disabled={disabled} checked={isVariantSelected} />
            )}
            <NailzyImage
              rounded
              image_url={variant?.image_urls?.[0]}
              style={{
                width: '50px',
                height: '50px',
                borderWidth: 1,
                borderColor: '#eee',
                borderStyle: 'solid'
              }}
            />

            <div>
              <b style={oneLineStyles}>{variant?.name}</b>
              {variant?.sku ? (
                <div style={{ fontSize: 10 }}>SKU: {variant?.sku || ''}</div>
              ) : null}
            </div>
          </div>
        </Col>
        <Col className="ps-4" md={2} lg={2}>
          <div
            className="fs--1 fw-medium"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {variant?.sku}
          </div>
        </Col>
        <Col className="ps-4" md={2} lg={2}>
          <span
            className={`fs--1 fw-medium ${
              disabled ? 'text-danger fw-bold' : ''
            }`}
          >
            {variant?.available_stock}
          </span>
        </Col>
        <Col className="ps-4" md={2} lg={2}>
          <span className="fs--1 fw-medium">
            {amountFormatter(variant?.price)}
          </span>
        </Col>
      </div>
    );
  };
  const onSelectAll = () => {
    if (foundProduct && foundProduct?.variants?.length) {
      onChange?.(product, false);
    } else if (product?.variants?.length) {
      onChange?.({
        ...product,
        variants: [...product?.variants]?.filter(x => x?.available_stock > 0)
      });
    }
    // setExpand(!isExpand);
  };
  return (
    <div>
      <Row
        onClick={() => setExpand(!isExpand)}
        className="clickable promotion-product-item gap-2 p-2 px-3  border-top"
      >
        <Col lg={12}>
          <div className="d-flex flex-row align-items-center w-100 gap-2">
            {viewOnly ? null : (
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelectAll();
                }}
              >
                <CheckBox
                  //   onChange={onSelectAll}
                  checked={
                    foundProduct?.variants?.length === product?.variants?.length
                  }
                />
              </div>
            )}
            {product?.variants?.length ? (
              <IconFA
                className={!isExpand ? 'chevron-right' : 'chevron-down'}
                color={'#AAA'}
              />
            ) : null}
            <NailzyImage
              rounded
              image_url={product?.image_url}
              style={{
                width: '50px',
                height: '50px',
                borderWidth: 1,
                borderColor: '#eee',
                borderStyle: 'solid'
              }}
            />

            <div>
              <b style={oneLineStyles}>{product?.name}</b>
              {product?.sku ? (
                <div style={{ fontSize: 10 }}>SKU: {product?.sku || ''}</div>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      {isExpand && product?.variants?.length ? (
        <div className="">
          {product?.variants?.map((x, i) => {
            return renderVariant(x, i);
          })}
        </div>
      ) : null}
    </div>
  );
};
export default ProductItem;
