import SortDropdownButton from 'components/common/buttons/SortDropdownButton';
import { t } from 'i18next';
import { useMemo } from 'react';

const SortBtn = ({ sortArray, ...props }) => {
  const defaultSortArray = useMemo(() => {
    return sortArray?.length
      ? sortArray
      : [
          { name: t('general.name_asc'), path: 'name_asc' },
          { name: t('general.name_desc'), path: 'name_desc' },
          { name: t('general.created_desc'), path: 'created_on_desc' },
          { name: t('general.created_asc'), path: 'created_on_asc' },
          { name: t('general.updated_desc'), path: 'updated_on_desc' },
          { name: t('general.updated_asc'), path: 'updated_on_asc' }
        ];
  }, [sortArray]);

  return <SortDropdownButton {...props} sortArray={defaultSortArray} />;
};

export default SortBtn;
