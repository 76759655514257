import React from 'react';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { useGetLogin } from 'hooks/useGetLogin';
import { Nav } from 'react-bootstrap';

const TopNavRightSideNavItem = () => {
  const { user } = useGetLogin();

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-end gap-2"
      as="ul"
    >
      {user?.role && <NotificationDropdown />}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
