import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useURLParams } from 'hooks/useURLParams';
import { Breadcrumb, Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTopLoading } from 'contexts/TopLoadingContext';
import DCInfo from './components/DCInfo';
import { ShippingAddress } from 'pages/orders/order-details/components';
import CustomerCard from '../../components/common/customerCard';
import { useEffect, useMemo, useState } from 'react';
import CouponCard from '../../components/common/couponCard';
import { amountFormatter, currencyFormatter, getColor } from 'helpers/utils';
import SelectProductModal from './components/SelectProductModal';
import SellerCard from './components/SellerCard';
import {
  useDeleteCarts,
  useDeleteDraftCart,
  useDraftCartPlaceOrder,
  useGetDraftCartDetails,
  useGetDraftCartShippingMethod,
  useGetPaymentIntent,
  useUpdateDraftCart
} from 'apis/DraftCartApi';
import { toast } from 'react-toastify';
import LoadingButton from 'components/common/buttons/LoadingButton';
import { useGetDraftCartPricingMethod } from '../../apis/DraftCartApi';
import IconFA from 'components/common/IconFA';
import { PlaceholderDetails } from 'components/common/PlaceHolder';
import { useQueryClient } from '@tanstack/react-query';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import PaymentModal from './components/PaymentModal';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

const DraftCartDetailsPage = () => {
  const { t } = useTranslation();
  const { setIsFetching } = useTopLoading();
  const navigate = useNavigate();
  const { id } = useParams();

  const { params, handleParamsChange } = useURLParams();
  const { data, isLoading } = useGetDraftCartDetails(id);
  useDocumentTitle(t('draft_carts.draft_cart') + ' # ' + data?.data?._id);
  const queryClient = useQueryClient();

  const handlePaginationChange = pagination => {
    handleParamsChange(pagination);
  };
  const [shippingData, setShippingData] = useState([]);
  const [customer, setCustomer] = useState();
  const [coupons, setCoupons] = useState();
  const [selectProductVisible, setSelectProductVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sellersData, setSellerData] = useState([]);
  const [cacheQuantity, setCacheQuantity] = useState({});
  const [needDelete, setNeedDelete] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [pricing, setPricing] = useState();
  const [paymentIntent, setPaymentIntent] = useState();
  const [deleteVariant, setDeleteVariant] = useState(null);
  const [deleteCart, setDeleteCart] = useState(null);
  const { mutate: updateDraftCart, isPending: updatingDraftCart } =
    useUpdateDraftCart();

  const { mutate: deleteDraftCart, isPending: deletingDraftCart } =
    useDeleteDraftCart();
  const { mutate: deleteCarts, isPending: deletingCarts } = useDeleteCarts();
  const { mutate: getShipping, isPending: gettingShipping } =
    useGetDraftCartShippingMethod();
  const { mutate: getPricing, isPending: gettingPricing } =
    useGetDraftCartPricingMethod();
  const { mutate: placeOrder, isPending: placingOrder } =
    useDraftCartPlaceOrder();
  const { mutate: getPaymentIntent, isPending: gettingPaymentIntent } =
    useGetPaymentIntent();

  const address = useMemo(() => {
    let addr = customer?.address?.find(x => !!x?.is_default);
    return addr || customer?.address?.[0];
  }, [customer]);
  const cart_ids = useMemo(() => {
    let array = [];
    for (let i = 0; i < data?.data?.cart_items.length; i++) {
      const cart_item = data?.data?.cart_items[i];
      for (let y = 0; y < cart_item?.items.length; y++) {
        const item = cart_item?.items[y];
        array?.push(item?._id);
      }
    }
    return array;
  }, [data]);
  const isCompleted = data?.data?.status === 'completed';

  const onRefetchShipping = () => {
    if (data?.data?._id) {
      getShipping(
        {
          _id: data?.data?._id,
          cart_ids: cart_ids,
          customer_id: data?.data?.customer?._id,
          shipping_address_id: data?.data?.shipping_address_id
        },
        {
          onSuccess: res => setShipping(res?.data || [])
        }
      );
    }
  };
  const onRefetchPricing = () => {
    let sellers = shippingData?.map((x, i) => {
      return {
        seller_id: x?.seller?._id,
        [x?.shipping?.provider === 'nailzy'
          ? 'shipping_nailzy_rate_id'
          : 'shipping_id']: x?.shipping?.id
      };
    });
    getPricing(
      { _id: data?.data?._id, sellers },
      {
        onSuccess: res => setPricing(res?.data)
      }
    );
  };
  useEffect(() => {
    if (shippingData?.length) {
      onRefetchPricing();
    }
  }, [shippingData]);

  useEffect(() => {
    if (data?.data) {
      setCustomer(data?.data?.customer);
      setCoupons(data?.data?.coupons);
      if (data?.data?.cart_items?.length) {
        let array = [];
        for (let i = 0; i < data?.data?.cart_items.length; i++) {
          const cart = data?.data?.cart_items[i];
          if (cart?.items?.length) {
            for (let y = 0; y < cart?.items.length; y++) {
              const variant = cart?.items[y];
              const data = {
                ...variant,
                _id: variant?._id,
                variant_id: variant?.variant_id,
                original_price: variant?.price,
                selling_price: variant?.discount_price,
                quantity: variant?.quantity
              };
              let prodIndex = array?.findIndex(
                x => x?._id === variant?.product_id
              );
              if (prodIndex > -1) {
                array[prodIndex].variants.push(data);
              } else {
                array.push({
                  _id: variant?.product_id,
                  name: variant?.product_name,
                  seller: cart?.seller,
                  order: cart?.order,
                  variants: [data]
                });
              }
            }
          }
        }
        setSelectedProducts(array);
      }
    }
  }, [data]);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.seller?._id) {
        let seller = array?.find(x => x?._id === product?.seller?._id);
        if (seller) {
          seller.products.push(product);
        } else {
          array.push({
            _id: product?.seller?._id,
            store_name: product?.seller?.store_name,
            order: data?.data?.orders?.find(
              x => x?.seller_id === product?.seller?._id
            ),
            products: [product]
          });
        }
      }
    }
    setSellerData(array);
  }, [selectedProducts]);

  const onChangeShipping = (shipping, seller) => {
    setShippingData(array => {
      array = array?.filter(x => x?.seller?._id !== seller?._id);
      array.push({ seller, shipping });
      return array;
    });
  };
  const onSelectCustomer = c => {
    setCustomer(c);
  };
  const handleParams = () => {
    let carts = [];
    let deleteIds = needDelete?.map(x => x?._id);
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.variants?.length) {
        for (let y = 0; y < product?.variants.length; y++) {
          const variant = product?.variants[y];
          deleteIds = deleteIds?.filter(x => x !== variant?._id);
          carts?.push({
            _id: variant?._id || undefined,
            product_id: product?._id,
            variant_id: variant?.variant_id || variant?._id,
            items_total: variant?.discount_price,
            quantity:
              cacheQuantity?.[variant?.variant_id] || variant?.quantity || 1
          });
        }
      }
    }
    return [
      {
        _id: id,
        customer_id: customer?._id,
        shipping_address_id: data?.data?.shipping_address_id,
        coupon_ids: coupons?.length ? coupons?.map(x => x?._id) : [],
        carts
      },
      deleteIds
    ];
  };
  const onUpdateSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['useGetDraftCartDetails', id]
    });
    if (shipping?.length) {
      onRefetchShipping();
    }
  };
  const onSaveCart = () => {
    const [params, deleteIds] = handleParams();
    updateDraftCart(params, {
      onSuccess: res => {
        if (res?.status) {
          toast.success(t('general.update_success'));

          if (deleteIds?.length) {
            deleteCarts(
              { _id: params?._id, ids: deleteIds },
              {
                onSuccess: () => {
                  onUpdateSuccess();
                }
              }
            );
          } else {
            onUpdateSuccess();
          }
        }
      }
    });
  };
  const onChangeQuantity = (product_id, variant_id, quantity) => {
    setCacheQuantity(prev => ({ ...prev, [variant_id]: quantity }));
  };
  const onDeleteVariant = () => {
    const { product_id, variant_id } = deleteVariant;
    setNeedDelete(prev => [...prev, deleteVariant]);
    let clone = [...selectedProducts];
    let prodIndex = clone?.findIndex?.(x => x?._id === product_id);
    if (prodIndex > -1) {
      let array = clone?.[prodIndex]?.variants?.filter?.(
        x => x?.variant_id !== variant_id
      );
      if (array?.length) {
        clone[prodIndex].variants = array;
      } else {
        delete clone[prodIndex];
      }
    }
    setSelectedProducts(clone);
    setDeleteVariant(null);
  };
  const onCloseDC = () => {
    const [params] = handleParams();
    updateDraftCart(
      { ...params, status: 'closed' },
      {
        onSuccess: res => {
          if (res?.status) {
            toast.success(t('general.update_success'));
            queryClient.invalidateQueries({
              queryKey: ['useGetDraftCart']
            });
            navigate('/draft-carts');
          }
        }
      }
    );
  };
  const onDeleteDC = () => {
    deleteDraftCart(
      { _id: id },
      {
        onSuccess: () => {
          toast.success(t('general.delete_success'));
          queryClient.invalidateQueries({
            queryKey: ['useGetDraftCart']
          });
          navigate('/draft-carts');
        }
      }
    );
  };

  const onPlaceOrder = (is_pay = false) => {
    let sellers = (shippingData || [])?.map((x, i) => {
      return {
        seller_id: x?.seller?._id,
        [x?.shipping?.provider === 'nailzy'
          ? 'shipping_nailzy_rate_id'
          : 'shipping_id']: x?.shipping?.id
      };
    });
    if (!sellers?.length) {
      toast.error(t('orders.please_select_shipping'));
      return;
    }
    if (total <= 0.5) {
      toast.error(t('orders.minimum_place_order'));
      return;
    }

    let params = {
      _id: id,
      sellers,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    placeOrder(params, {
      onSuccess: res => {
        toast.success(t('orders.order_success'));
        queryClient.invalidateQueries({
          queryKey: ['useGetDraftCartDetails', id]
        });
        queryClient.invalidateQueries({
          queryKey: ['useGetDraftCart']
        });
        queryClient.invalidateQueries({
          queryKey: ['useAdminProduct']
        });
        if (is_pay) {
          getPaymentIntent(
            { order_transaction_id: res?.data?.order_transaction_id },
            {
              onSuccess: intent => {
                setPaymentIntent(intent?.data);
              }
            }
          );
        }
      }
    });
  };
  const onClickOption = (key = '') => {
    if (key === 'close') {
      onCloseDC();
    } else if (key === 'delete') {
      setDeleteCart(id);
    } else if (key === 'no_pay') {
      onPlaceOrder();
    } else if (key === 'credit') {
      onPlaceOrder(true);
    } else if (key === 'link') {
      console.log('________key:', key);
    }
  };
  const [tempTotal, tempDiscount, tempSubtotal] = useMemo(() => {
    let total = 0,
      subtotal = 0;
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.variants?.length) {
        for (let y = 0; y < product?.variants.length; y++) {
          const variant = product?.variants[y];
          const qty =
            cacheQuantity?.[variant?.variant_id] || variant?.quantity || 1;
          total += variant?.price * qty;
          subtotal += variant?.discount_price * qty;
        }
      }
    }
    return [total, total - subtotal, subtotal];
  }, [selectedProducts, cacheQuantity]);

  // RENDER
  const renderLine = (name, amout, type = 'default') => {
    return (
      <div className="d-flex flex-row align-item-center justify-content-between mb-1">
        <div className="fs-0  ">{name}</div>
        <div
          className={`fs-${type === 'total' ? '2 fw-semi-bold' : '1'} text-${
            type === 'discount' ? 'danger' : 'black'
          }`}
        >
          {amout
            ? (type === 'discount' ? '-' : '') + currencyFormatter.format(amout)
            : '--'}
        </div>
      </div>
    );
  };

  const {
    coupon_discount_amount,
    items_discount,
    items_total,
    sales_tax,
    shipping: shipping_amount,
    sub_total,
    total
  } = pricing || {};
  return (
    <>
      <Row className="my-3">
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: '/' }}
              className="fs--1"
            >
              <FontAwesomeIcon icon={faHome} /> {t('navigation.home')}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="fs--1"
              linkAs={Link}
              linkProps={{ to: '/draft-carts' }}
            >
              {t('draft_carts.draft_carts')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="fs--1">
              {data?.data?._id}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {isLoading ? (
        <PlaceholderDetails />
      ) : (
        <Row className="g-3">
          <Col xs={12} lg={8}>
            <DCInfo
              data={data?.data}
              onSelect={() => setSelectProductVisible(true)}
            />
            {sellersData?.map((x, i) => (
              <SellerCard
                isCompleted={isCompleted}
                seller={x}
                key={x?._id}
                onChangeQuantity={onChangeQuantity}
                cacheQuantity={cacheQuantity}
                onDeleteVariant={setDeleteVariant}
                shipping={shipping?.find(y => y?.seller_id === x?._id)}
                onChangeShipping={v => onChangeShipping(v, x)}
                onRefetchShipping={onRefetchShipping}
                gettingShipping={gettingShipping}
              />
            ))}
            <SelectProductModal
              selectedProducts={selectedProducts}
              visible={selectProductVisible}
              onHide={() => setSelectProductVisible(false)}
              onSave={setSelectedProducts}
            />
            {sellersData?.length ? (
              <Card className="mb-3">
                <Card.Header>
                  <div className="mb-2 d-flex align-items-center gap-1">
                    <h5 className="mb-0">{t('orders.summary')}</h5>
                    {isCompleted || !pricing ? null : (
                      <IconFA
                        type="regular"
                        onClick={() => onRefetchPricing()}
                        className="arrows-rotate"
                        color={getColor('gray-700')}
                      />
                    )}
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  {gettingPricing ? (
                    <div className="d-flex flex-center flex-column">
                      <FontAwesomeIcon
                        size="3x"
                        spin
                        icon="spinner"
                        className="text-primary"
                      />
                      <p className="mt-2 fs--1">
                        {t('orders.fetching_pricing')}
                      </p>
                    </div>
                  ) : (
                    <Row className="justify-content-end">
                      <Col lg={6}>
                        {renderLine(t('orders.items_total'), tempTotal)}
                        {renderLine(
                          t('orders.items_discount'),
                          tempDiscount,
                          'discount'
                        )}
                        {renderLine(t('orders.subtotal'), tempSubtotal)}
                        {!pricing ? null : (
                          <>
                            {renderLine(
                              t('orders.coupon_discount'),
                              coupon_discount_amount,
                              'discount'
                            )}
                            {/* {renderLine('Nailzy coupon', 0, 'discount')} */}
                            {renderLine(t('orders.shipping'), shipping_amount)}
                            {renderLine(t('orders.sales_tax'), sales_tax)}
                            {renderLine(
                              t('orders.total_pricing'),
                              total,
                              'total'
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            ) : null}
          </Col>

          <Col xs={12} lg={4}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <CustomerCard
                isCompleted={isCompleted}
                customer={customer}
                onSave={onSelectCustomer}
              />
              <ShippingAddress
                isCompleted={isCompleted}
                disabled={!customer}
                canAdd
                shippingAddress={customer?.address?.[0]}
                customer={customer}
                onUpdateAddress={address => {
                  setCustomer({ ...customer, address: [address] });
                }}
              />
              {cart_ids?.length ? (
                <CouponCard
                  id={id}
                  isCompleted={isCompleted}
                  coupons={coupons}
                  onSave={setCoupons}
                />
              ) : null}
              {isCompleted ? null : (
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-row-reverse gap-2 align-items-center">
                    <LoadingButton
                      isLoading={updatingDraftCart}
                      disabled={
                        !customer || !address || !selectedProducts?.length
                      }
                      onClick={() => onSaveCart()}
                    >
                      {t('draft_carts.save_draft')}
                    </LoadingButton>

                    {/* // */}
                    <Dropdown
                      className="font-sans-serif btn-reveal-trigger "
                      align={'start'}
                      drop={'start'}
                      style={{
                        position: 'static'
                      }}
                    >
                      <Dropdown.Toggle
                        variant="falcon-default"
                        data-boundary="viewport"
                        // className={classNames('text-600', {
                        //   [btnRevealClass]: btnRevealClass,
                        //   'btn-reveal': !btnRevealClass
                        // })}
                      >
                        <IconFA className={'ellipsis'} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu flip={true} className="border py-0 ">
                        <div className="py-2 ">
                          <Dropdown.Item
                            onClick={() => onClickOption('no_pay')}
                          >
                            {t('orders.order_without_paying')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => onClickOption('credit')}
                          >
                            {t('orders.pay_with_credit')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled
                            onClick={() => onClickOption('link')}
                          >
                            {t('orders.send_payment_link')}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => onClickOption('close')}>
                            {t('general.close')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ color: getColor('danger') }}
                            onClick={() => onClickOption('delete')}
                          >
                            {t('general.delete')}
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    {deletingDraftCart ? (
                      <FontAwesomeIcon spin icon="spinner" />
                    ) : null}
                  </Card.Body>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      )}

      {!paymentIntent ? null : (
        <PaymentModal
          visible={!!paymentIntent}
          onHide={() => setPaymentIntent(null)}
          data={paymentIntent}
          total={total}
        />
      )}
      {/* Delete warning */}
      <DeleteWarningModal
        name={deleteCart}
        onShow={!!deleteCart}
        onClose={() => setDeleteCart(null)}
        onDelete={onDeleteDC}
      />
      {/* Delete warning */}
      <DeleteWarningModal
        name={deleteVariant?.variant_name}
        onShow={!!deleteVariant}
        onClose={() => setDeleteVariant(null)}
        onDelete={onDeleteVariant}
      />
    </>
  );
};

export default DraftCartDetailsPage;
