export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
};

export const communicationRoutes = {
  label: 'Customer service',
  labelDisable: true,
  children: [
    {
      name: 'Customer service',
      icon: 'bullhorn',
      active: true,
      children: [
        {
          name: 'Inbox',
          to: '/inbox',
          active: true
        },
        {
          name: 'Reviews',
          to: '/reviews',
          active: true
        },
        {
          name: 'Push notifications',
          to: '/notification',
          active: true
        },
        {
          name: 'Information',
          to: '/information?&type=pages',
          active: true
        },
        {
          name: 'Help articles',
          to: '/help-articles?section=shopping_with_nailzy',
          active: true
        }
      ]
    }
  ]
};

export const marketplaceRoutes = {
  label: 'Marketplace',
  children: [
    {
      name: 'Sales',
      active: true,
      icon: 'shopping-cart',
      children: [
        {
          name: 'Orders',
          to: '/orders',
          active: true
        },
        {
          name: 'Draft carts',
          to: '/draft-carts',
          active: true
        }
      ]
    },
    {
      name: 'Inventory',
      active: true,
      icon: 'tag',
      children: [
        {
          name: 'Products',
          to: '/products',
          active: true
        },
        {
          name: 'Categories',
          to: '/categories',
          active: true
        },
        {
          name: 'Brands',
          to: '/brands',
          active: true
        }
      ]
    },
    {
      name: 'Marketing center',
      active: true,
      icon: 'percentage',
      children: [
        {
          name: 'Promotions',
          to: '/promotions',
          active: true
        },
        {
          name: 'Banners',
          to: '/banners',
          active: true
        },
        {
          name: 'Flash sales',
          to: '/flash-sales',
          active: true
        },
        {
          name: 'Coupons',
          to: '/coupons',
          active: true
        },
        {
          name: 'Discounts',
          to: '/discounts',
          active: true
        },
        {
          name: 'Shipping discounts',
          to: '/shipping-discounts',
          active: true
        },
        {
          name: 'Combo deals',
          to: '/mega-combos',
          active: true
        },
        {
          name: 'Bonus gifts',
          to: '/bonus-gift',
          active: true
        }
      ]
    },
    {
      name: 'Finances',
      active: true,
      icon: 'dollar-sign',
      children: [
        {
          name: 'Balance',
          to: '/balance',
          active: true
        },
        {
          name: 'Transactions',
          to: '/transactions',
          active: false
        },
        {
          name: 'Payouts',
          to: '/payouts',
          active: false
        }
      ]
    }
  ]
};

export const usersRoutes = {
  label: 'Users',
  children: [
    {
      name: 'Customers',
      icon: 'users',
      to: '/customers',
      active: true
    },
    {
      name: 'Sellers',
      icon: 'user-tag',
      to: '/sellers',
      active: true
    }
  ]
};

export const postingsRoutes = {
  label: 'Postings',
  labelDisable: false,
  children: [
    {
      name: 'Posts',
      active: true,
      icon: 'file-alt',
      children: [
        {
          name: 'Jobs',
          to: '/jobs',
          active: true
        },
        {
          name: 'Techs',
          to: '/techs',
          active: true
        },
        {
          name: 'Salons',
          to: '/salons',
          active: true
        },
        {
          name: 'Skills',
          to: '/skills',
          active: true
        }
      ]
    },
    {
      name: 'Events',
      icon: 'user-graduate',
      to: '/events',
      active: true
    }
  ]
};

export const settingsRoutes = {
  label: 'Settings',
  labelDisable: false,
  children: [
    {
      name: 'Account',
      active: true,
      icon: 'user-circle',
      children: [
        {
          name: 'Profile & Security',
          to: '/account',
          active: true
        },
        {
          name: 'System users',
          to: '/system-users',
          active: true
        }
      ]
    },
    {
      name: 'Shipping',
      icon: 'truck',
      active: true,
      children: [
        {
          name: 'Warehouse',
          to: '/warehouse',
          active: true
        },
        {
          name: 'Packages',
          to: '/packages',
          active: true
        },
        {
          name: 'Shipping',
          to: '/shipping',
          active: true
        },
        {
          name: 'Sales taxes',
          to: '/sales-taxes',
          active: true
        }
      ]
    }
  ]
};

export default [
  dashboardRoutes,
  communicationRoutes,
  marketplaceRoutes,
  usersRoutes,
  postingsRoutes,
  settingsRoutes
];
