import IconFA from 'components/common/IconFA';
import SoftBadge from 'components/common/SoftBadge';
import { onCopyToClipBoard, phoneFormatter } from 'helpers/utils';
import { t } from 'i18next';

const AddressInfo = ({ address }) => {
  const formattedPhone = phoneFormatter(address?.phone);

  return (
    <div className="fs--1 text-black">
      <div className="d-flex gap-2 mb-1 align-items-center">
        <p>
          {address?.f_name} {address?.l_name}
        </p>
        <SoftBadge pill bg={'info'} className="fs--2">
          {address?.type === 'business'
            ? t('orders.business_address')
            : t('orders.residential_address')}
        </SoftBadge>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mb-1">
        <p className="mb-1 pe-1" style={{ flex: 1 }}>
          {address?.location}{' '}
        </p>
        <IconFA
          onClick={() =>
            window.open(
              `https://maps.google.com?q=${address?.location}`,
              '_blank'
            )
          }
          className={'map'}
          type="regular"
        />
        <IconFA
          onClick={() => onCopyToClipBoard(address?.location || '')}
          className={'copy'}
          type="regular"
        />
      </div>
      <p className="mb-1">{formattedPhone}</p>
    </div>
  );
};
export default AddressInfo;
