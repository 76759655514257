import {
  faLanguage,
  faQuestionCircle,
  faSignOutAlt,
  faUserCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import { supportLink } from 'config';
import { useAuth } from 'contexts/AuthContext';
import i18next from 'i18next';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  const { user } = useAuth();

  // Changing language
  const { t } = useTranslation();
  const toggleLanguage = () => {
    const currentLanguage = localStorage.getItem('languages');
    const newLanguage = currentLanguage === 'en' ? 'vi' : 'en';
    localStorage.setItem('languages', newLanguage);
    i18next.changeLanguage(newLanguage);
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="nav-link d-flex align-items-center"
      >
        <Avatar
          src={user ? user.profile_image_url : null}
          name={user ? `${user?.first_name} ${user?.last_name}` : 'Anonymous'}
          className="align-self-center"
        />

        <div className="ps-2">
          <p className="m-0 fw-medium text-700">
            {user ? `${user?.first_name} ${user?.last_name}` : ''}
          </p>
          <p className="m-0 fw-normal text-700">{user?.email}</p>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={'/account'}>
            <FontAwesomeIcon icon={faUserCircle} className="me-2" />
            {t('account.account')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={'/logout'}>
            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
            {t('login.logout')}
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item onClick={() => toggleLanguage()}>
            <FontAwesomeIcon icon={faLanguage} className="me-2" />
            {t('navigation.language')}
          </Dropdown.Item>

          <Dropdown.Item href={supportLink} target="_blank">
            <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
            {t('navigation.help_center')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
