import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PaymentStatus from 'components/common/PaymentStatus';
import { currencyFormatter } from 'helpers/utils';

const getBrandIcon = brand => {
  switch (brand) {
    case 'visa':
      return 'cc-visa';
    case 'mastercard':
      return 'cc-mastercard';
    case 'amex':
      return 'cc-amex';
    case 'discover':
      return 'cc-discover';
    default:
      return 'cc-stripe';
  }
};

export const PaymentMethod = React.memo(({ data }) => {
  const { t } = useTranslation();
  const paymentStatus = data?.payment_status;
  const paymentInformation = data?.payment_information;
  const last4 = paymentInformation?.last4 || '';
  // const expireDate = paymentInformation
  //   ? `${
  //       paymentInformation?.exp_month > 9
  //         ? paymentInformation?.exp_month
  //         : `0${paymentInformation?.exp_month}`
  //     }/${paymentInformation?.exp_year}`
  //   : '';
  const { line1, line2, state, city, country, postal_code } =
    paymentInformation?.billing_details?.address || {};
  const location = [line1, line2, state, city, postal_code, country]
    ?.filter(x => !!x)
    ?.join(', ');
  const riskScore = paymentInformation?.outcome?.risk_score;
  const refundedAmount =
    paymentInformation?.refunded_amount > 0 &&
    currencyFormatter.format(paymentInformation?.refunded_amount);

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5>{t('orders.payment_information')}</h5>
        <div className="d-flex flex-column align-items-start gap-2 mb-2 fs--1">
          <div className="d-flex align-items-center gap-2">
            <PaymentStatus status={paymentStatus} className="fs--1" />
            <span className="text-danger fw-bold">{refundedAmount}</span>
          </div>
          {paymentInformation && (
            <>
              <div className="d-flex align-items-center gap-2">
                <i
                  className={`fa-brands fa-${getBrandIcon(
                    paymentInformation?.brand
                  )} fs-2`}
                ></i>
                <span>{last4 ? `*${last4}` : ''}</span>
              </div>
              <p>
                {t('orders.billing_address')}: {location}
              </p>
              <p>
                {t('orders.fraud_analysis')}:{' '}
                {riskScore > 65 ? (
                  <span className="text-danger fw-bold">
                    {t('orders.high_risk')}
                  </span>
                ) : (
                  <span className="text-success fw-bold">
                    {t('orders.normal_risk')}
                  </span>
                )}
              </p>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
});

PaymentMethod.propTypes = {
  data: PropTypes.object.isRequired
};
