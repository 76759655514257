import axios from 'axios';
import { logErrors } from 'helpers/logging';
import { toast } from 'react-toastify';
import { apiSetting } from 'config';
import { auth } from 'firebase-app';
import * as Sentry from '@sentry/browser';

const { LOGOUT_WAIT_TIME, CATCH_ERROR_TIME_OUT } = apiSetting;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION_1 = 'v1';
const API_VERSION_2 = 'v2';

let lastErrorMessage = null;
let lastErrorTime = null;

function logout() {
  setTimeout(() => {
    document.location.href = '/logout';
  }, LOGOUT_WAIT_TIME);
}

export class NetworkError extends Error {
  constructor(msg, description) {
    super(msg);
    this.name = 'NetworkError';
    this.description = description;
  }
}
export class DuplicateError extends Error {
  constructor(msg) {
    super(msg);
    this.name = 'DuplicateError';
  }
}

const handleError = error => {
  // Check if the error message is the same as the last error message
  if (error.message === lastErrorMessage) {
    // Prevent toast out the same errors many time
    if (Date.now() - lastErrorTime < CATCH_ERROR_TIME_OUT) {
      return;
    }
  }

  if (error.code !== 'ERR_CANCELED' && error.code !== 'ECONNABORTED') {
    logErrors(error);
  }

  if (error.response && error.response.status !== 401) {
    console.error('HTTP error:', error.response.status);
    console.error('Response data:', error.response.data);
  }

  if (error.isAxiosError) {
    console.error('Network error:', error.message);
    console.error('Error code:', error.code);
  }

  if (error?.response?.status === 401) {
    // toast.info('Session timeout. Please login again!');
    // logout();
  }

  // If the error is new, set the last error message and time
  lastErrorMessage = error.message;
  lastErrorTime = Date.now();
  if (error?.response?.status !== 401) {
    error?.resposne?.data
      ? toast.error(`${error.response?.data?.message}`)
      : toast.error(`${error.message}`);
  }

  return error;
};

/**
 * API function for request with 'application/json'
 * @param {string} url api request
 * @param {object} data request body
 * @param {object} newHeaders request header
 * @param {func} signal signal for cancelling request
 * @returns response data from server
 */
export const apiCall = async (url, data, newHeaders = {}, signal = null) => {
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  try {
    const requestUrl = `${API_BASE_URL}/${API_VERSION_1}/${url}`;
    let headers = new Headers();

    headers = {
      authorization: `Bearer ${userLocalInfo?.data?.token}`,
      'Content-Type': 'application/json',
      ...newHeaders
    };

    const source = axios.CancelToken.source();

    let res = await axios.post(requestUrl, data, {
      headers,
      signal,
      cancelToken: source.token
    });

    return res?.data;
  } catch (error) {
    // handleError(error);
  }
};

/**
 * API function for request with 'application/json'
 * @param {string} url api request
 * @param {object} data request body if have
 * @param {object} headerOptions request header
 * @param {func} signal signal for cancelling request
 * @returns {object}
 *  {
 *    data?: {
 *      results[],
 *      total
 *    },
 *    status,
 *    message
 *  }
 */
export const apiCallV2 = async ({
  method = 'GET',
  url,
  headerOptions = {},
  params = undefined,
  data = undefined,
  signal = undefined
}) => {
  let headers = new Headers();

  const token = await auth?.currentUser?.getIdToken();

  headers = {
    ...(token ? { authorization: `Bearer ${token}` } : {}),
    'Content-Type': headerOptions['Content-Type']
      ? headerOptions['Content-Type']
      : 'application/json',
    ...headerOptions
  };

  try {
    const response = await axios({
      method,
      url,
      baseURL: `${API_BASE_URL}/${API_VERSION_2}/`,
      params,
      headers,
      data,
      signal
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (response?.data.status === false) {
      throw new Error(`HTTP error status: ${response.status}`);
    }
    return response?.data;
  } catch (error) {
    // Check if the error message is the same as the last error message
    // if (error.message === lastErrorMessage) {
    //   // Prevent toast out the same errors many time
    //   if (Date.now() - lastErrorTime < CATCH_ERROR_TIME_OUT) {
    //     return;
    //   }
    // }

    if (error.code !== 'ERR_CANCELED' && error.code !== 'ECONNABORTED') {
      logErrors(error);
    }

    if (error?.response?.status === 401) {
      toast.error('Permission denied! Please log in again!');
      logout();
      return;
    }

    // if (error?.response?.status === 400 && error?.response?.data?.message) {
    //   lastErrorMessage = error.message;
    //   lastErrorTime = Date.now();
    // }

    lastErrorMessage = error.message;
    lastErrorTime = Date.now();

    const networkError = new NetworkError(error?.response?.data?.message, {
      statusText: error.response.statusText,
      errorCode: error.response.status,
      ...error?.response?.data?.data
    });

    if (error?.response?.status === 500 || error?.response?.status === 404) {
      Sentry.captureException(networkError, 'debug');
    }

    throw networkError;
  }
};

/**
 * API function for request with 'multipart/form-data'
 * @param {string} url api request
 * @param {object} data request body
 * @param {object} newHeaders request header
 * @param {func} signal signal for cancelling request
 * @returns response data from server
 */
export const apiCallFile = async (
  url,
  data,
  newHeaders = {},
  signal = null
) => {
  // get user logins
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));

  try {
    const requestUrl = `${API_BASE_URL}/${API_VERSION_1}/${url}`;
    let headers = new Headers();
    headers = {
      authorization: `Bearer ${userLocalInfo?.data?.token}`,
      'Content-Type': 'multipart/form-data',
      ...newHeaders
    };

    const source = axios.CancelToken.source();

    let res = await axios.post(requestUrl, data, {
      headers,
      signal,
      cancelToken: source.token
    });

    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
