import { useMutation, useQuery } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import queryString from 'query-string';
import { apiCall, apiCallFile, apiCallV2 } from './ApiCall';
import { categoryKeys } from 'query-keys/category-keys';

const config = dataFetchingQueryConfig;
const CATEGORIES_URL = 'admin/categories';

export const useGetCategories = (params = {}, enabled = true) => {
  const query = queryString.stringify(params);
  const url = `${CATEGORIES_URL}?${query ? query : ''}`;

  return useQuery({
    queryKey: categoryKeys.list(params),
    queryFn: () => apiCallV2({ url }),
    ...config,
    enabled
  });
};

export const useGetCategoryDetails = (id, options = {}) => {
  const controller = new AbortController();
  const url = `${CATEGORIES_URL}/${id}`;

  return useQuery({
    queryKey: categoryKeys.detail(id),
    queryFn: () => apiCallV2({ url, signal: controller.signal }),
    staleTime: Infinity,
    ...options
  });
};

export const useGetCategoriesFilter = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'category/get_all_category_filter';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery(
    [...key],
    () => apiCall(url, body, headers, controller.signal),
    config
  );
};

export const useAddCategory = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = CATEGORIES_URL;
      const headers = {};

      return apiCallV2({
        url,
        headers,
        data,
        signal: controller.signal,
        method: 'POST'
      });
    }
  });

  return mutation;
};

export const useUpdateCategory = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = CATEGORIES_URL;

      return apiCallV2({
        method: 'PUT',
        url,
        data: { categories: Array.isArray(data) ? data : [data] },
        signal: controller.signal
      });
    }
  });

  return mutation;
};

/**
 * Custom hook to add category images using `useMutation` from `@tanstack/react-query`
 *
 * @param {Object} data - Object with binary image file.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useAddCategoryImg = () => {
  const mutation = useMutation(data => {
    const controller = new AbortController();
    const url = 'category/upload_cat_media';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

export const useDeleteCategory = () => {
  const mutation = useMutation({
    mutationFn: data => {
      const controller = new AbortController();
      const url = `admin/categories/${data._id}`;
      const headers = {};

      return apiCallV2({
        method: 'DELETE',
        url,
        data,
        headers,
        signal: controller.signal
      });
    }
  });

  return mutation;
};

/**
 * This function is used to export categories data to an Excel file.
 * @param {object} data - An optional object containing data to be passed as request parameters.
 * @returns {Promise} - A promise that resolves with the exported Excel file data.
 */
export async function exportCategories(data = {}) {
  const controller = new AbortController();
  const url = 'category/export_excel';
  const body = {
    ...data
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}
