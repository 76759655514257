import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useTranslation } from 'react-i18next';
import LoadingButton from './buttons/LoadingButton';

export const ExportModal = ({
  name,
  count,
  showExportModal,
  handleCloseModal,
  handleExport,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showExportModal} onHide={handleCloseModal} centered>
      <Modal.Header>
        <Modal.Title>{`${t('general.export')} ${name}`}</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>

      <Modal.Body>
        <p>
          {`${t('general.export_desc')} ${count} ${name} ${t(
            'general.export_desc_2'
          )}`}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleCloseModal}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          variant="primary"
          isLoading={isLoading}
          onClick={handleExport}
          size="md"
        >
          <span>
            {isLoading ? t('general.exporting') : t('general.export')}
          </span>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};
