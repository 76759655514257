import { getProfile } from 'apis/AuthApi';
import { auth } from 'firebase-app';
import {
  fetchSignInMethodsForEmail,
  onIdTokenChanged,
  signOut,
  updatePassword
} from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const AuthContext = createContext({
  user: undefined,
  isLoggedIn: false,
  loading: false,
  signOutFirebase: undefined
});

export function AuthProvider({ children }) {
  const [firebaseUser, setFirebaseUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const checkLinkProvider = providerId =>
    firebaseUser?.providerData?.findIndex(
      item => item.providerId === providerId
    ) > -1;

  const handleUpdatePassword = newPassword =>
    updatePassword(auth.currentUser, newPassword);

  const clear = () => {
    setIsLoggedIn(false);
    setFirebaseUser(undefined);
    setUser(undefined);
    localStorage.removeItem('token');
  };

  const logout = () => signOut(auth).then(clear);

  const getUserProfile = async () => {
    try {
      setIsLoading(true);
      const result = await getProfile();
      setUser(result?.data);
    } catch (error) {
      toast.error(error?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const idTokenChanged = authState => {
    if (!authState) return;

    setFirebaseUser({ ...authState });
    setIsLoggedIn(true);
    localStorage.setItem('token', authState?.accessToken);
  };

  const checkIfEmailExist = async email => {
    try {
      const result = await fetchSignInMethodsForEmail(auth, email);
      return result?.length > 0;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, idTokenChanged);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!isLoggedIn || !firebaseUser) return;

    getUserProfile();
  }, [isLoggedIn, firebaseUser]);

  const value = {
    user,
    getUserProfile,
    isLoggedIn,
    isLoading,
    firebaseUser,
    logout,
    checkLinkProvider,
    handleUpdatePassword,
    checkIfEmailExist
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
