import React from 'react';
import IconButton from 'components/common/IconButton';
import FormatSelectOptionLabelWithStatus from 'components/common/inputs/FormatSelectOptionLabel';
import SelectInput from 'components/common/inputs/SelectInput';
import { useState } from 'react';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { removeEmptyFields } from 'helpers/utils';
import { toast } from 'react-toastify';
import FalconCloseButton from 'components/common/FalconCloseButton';
import ReactDatePicker from 'react-datepicker';
import { useExportCustomers, useGetCustomers } from 'apis/CustomersApi';
import dayjs from 'dayjs';
import { useURLParams } from 'hooks/useURLParams';
import { StatusOptions } from 'components/common/inputs/StatusInput';
import { CustomerTitleTypeOptions } from 'constants/customers';

const ExportCustomerModal = ({ visible, onHide, current_items }) => {
  const { t } = useTranslation();
  const { params } = useURLParams();
  const { page, limit, status, title_type } = params;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterData, setFilterData] = useState({
    type: 'current_page',
    page: 1,
    limit: 1000
  });
  const {
    data,
    refetch: downloadCustomers,
    isLoading: exporting,
    isRefetching
  } = useExportCustomers(
    filterData?.type === 'current_page'
      ? {
          page: parseInt(page) + 1,
          limit: parseInt(limit),
          status: status || undefined,
          title_type: title_type || undefined,
          ['created_on[from]']: params?.['created_on[from]'] || undefined,
          ['created_on[to]']: params?.['created_on[to]'] || undefined
        }
      : {
          ...removeEmptyFields(filterData),
          ['created_on[from]']: startDate ? dayjs(startDate).unix() : undefined,
          ['created_on[to]']: endDate ? dayjs(endDate).unix() : undefined,
          type: undefined
        }
  );
  const isLoading = exporting || isRefetching;

  const onChangeDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleSubmitBtn = () => {
    downloadCustomers().then(res => {
      if (res?.data?.status) {
        window.open(res?.data?.message, '_blank');
        toast.success(t('general.export_success'));
      } else {
        toast.error(t('general.export_fail'));
      }
    });
  };

  return (
    <Modal show={visible} onHide={onHide} centered size="lg">
      <Modal.Header>
        <Modal.Title>{`${t('customer.export_customer')} ${name}`}</Modal.Title>
        <FalconCloseButton onClick={onHide} />
      </Modal.Header>

      <Modal.Body>
        <Card.Header>
          <Row>
            <div className="">
              <Form.Check // prettier-ignore
                type="radio"
                id="current_page"
                className="mb-0"
                checked={filterData?.type === 'current_page'}
                onChange={e =>
                  setFilterData({
                    ...filterData,
                    type: e.target.value ? 'current_page' : 'filters'
                  })
                }
                label={`${t('orders.current_page')} (${current_items} ${t(
                  'navigation.customers'
                ).toLowerCase()})`}
              />
              <Form.Check // prettier-ignore
                type="radio"
                id="filters"
                className="mb-0"
                label={t('filter.filters')}
                checked={filterData?.type === 'filters'}
                onChange={e =>
                  setFilterData({
                    ...filterData,
                    type: !e.target.value ? 'current_page' : 'filters'
                  })
                }
              />
            </div>
            <div>
              <Row className="rounded-2">
                {filterData?.type === 'current_page' ? null : (
                  <>
                    <Col md={6} className="mb-3">
                      <Form.Label> {t('general.status')} </Form.Label>
                      <SelectInput
                        closeMenuOnSelect={true}
                        formatOptionLabel={FormatSelectOptionLabelWithStatus}
                        options={StatusOptions?.map(x => ({
                          ...x,
                          label: t(x.label)
                        }))}
                        placeholder={t('orders.select_status')}
                        onChange={value => {
                          setFilterData(prev => ({
                            ...prev,
                            status: value?.value
                          }));
                        }}
                        isClearable
                      />
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Label> {t('filter.role')} </Form.Label>
                      <SelectInput
                        closeMenuOnSelect={true}
                        formatOptionLabel={FormatSelectOptionLabelWithStatus}
                        options={CustomerTitleTypeOptions?.map(x => ({
                          ...x,
                          label: t(x.label)
                        }))}
                        placeholder={t('general.select_role')}
                        onChange={value => {
                          setFilterData(prev => ({
                            ...prev,
                            title_type: value?.value
                          }));
                        }}
                        isClearable
                      />
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Label>{t('filter.joined_date_range')} </Form.Label>
                      <ReactDatePicker
                        isClearable
                        selected={startDate}
                        onChange={onChangeDate}
                        startDate={startDate}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDate}
                        selectsRange
                        className="form-control"
                        placeholderText={t('general.select_date_range')}
                      />
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Label>{t('general.page')}</Form.Label>
                      <Form.Control
                        type="number"
                        name="page"
                        disabled={filterData?.type === 'current_page'}
                        value={
                          filterData?.type === 'current_page'
                            ? parseInt(page) + 1
                            : filterData?.page
                        }
                        placeholder={t('general.all')}
                        min={1}
                        onChange={e =>
                          setFilterData(prev => ({
                            ...prev,
                            page: e.target.value ? Number(e.target.value) : ''
                          }))
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Label>{t('general.limit')}</Form.Label>
                      <Form.Control
                        type="number"
                        name="limit"
                        placeholder={t('general.all')}
                        disabled={filterData?.type === 'current_page'}
                        value={
                          filterData?.type === 'current_page'
                            ? parseInt(limit)
                            : filterData?.limit
                        }
                        min={1}
                        onChange={e =>
                          setFilterData(prev => ({
                            ...prev,
                            limit: e.target.value ? Number(e.target.value) : ''
                          }))
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Row>
          <div className="gap-2 mt-2 d-flex flex-row justify-content-end">
            <IconButton
              className=""
              variant="falcon-default"
              icon=""
              transform="shrink-3"
              onClick={onHide}
            >
              {t('general.close')}
            </IconButton>
            <IconButton
              variant="primary"
              type="submit"
              icon={isLoading ? 'spinner' : ''}
              transform="shrink-3"
              onClick={handleSubmitBtn}
              disabled={isLoading}
              spin={isLoading}
            >
              <span>{t('general.export')}</span>
            </IconButton>
          </div>
        </Card.Header>
      </Modal.Body>
    </Modal>
  );
};
export default ExportCustomerModal;
