import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { ExportModal } from 'components/common/ExportModal';
import { exportUsers } from 'apis/UsersApi';
import { toast } from 'react-toastify';
import { openFile } from 'helpers/utils';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../queryKeys';
import { useTranslation } from 'react-i18next';
import ExportCustomerModal from './ExportCustomerModal';

export const UserListHeader = ({
  selectedRowIds,
  selectedFlatRows,
  handleAddButton,
  mutateAsync,
  count
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [action, setAction] = useState(null);

  // Export modal
  const [showExportModal, setShowExportModal] = useState(false);
  const handleShowExportModal = () => setShowExportModal(true);
  const handleCloseModal = () => setShowExportModal(false);

  // Export action button
  const [isLoading, setIsLoading] = useState(false);

  async function changeStatus() {
    try {
      let message = '';
      const submitData = selectedFlatRows.map(row => ({
        id: row.original?._id?._id,
        status: row.original.status == 1 ? '0' : '1'
      }));

      for (const [_key, value] of Object.entries(submitData)) {
        const res = await mutateAsync({
          user_id: value.id,
          status: value.status
        });

        if (res.message !== message) {
          toast.success(`${res.message}`);
          message = res.message;
        }
      }
    } catch (error) {
      toast.error(`${error}`);
      throw new Error(error);
    }
  }

  const handleBulkActions = async () => {
    if (action === 'change-status') await changeStatus();

    if (action === 'delete-all') console.log('delete all');

    setAction(null);
    queryClient.invalidateQueries(userKeys.lists());
  };

  const handleChangeStatusBtn = value => {
    setAction(value);
  };

  const handleExport = () => {
    setIsLoading(true);

    exportUsers()
      .then(data => {
        openFile(data?.data);
        setShowExportModal(false);
        toast.success(`${data?.message}`);
      })
      .catch(error => {
        toast.error(`${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Row className="flex-between-center">
        <Col sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="mb-0 text-nowrap py-2 py-xl-0">
            {Object.keys(selectedRowIds).length > 0
              ? Object.keys(selectedRowIds).length + ' customers selected'
              : t('navigation.customers')}
          </h5>
        </Col>
        <Col sm="auto">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                onChange={e => handleChangeStatusBtn(e.target.value)}
                size="sm"
                aria-label="Bulk actions"
              >
                <option>{t('general.bulk_actions')}</option>
                <option>{t('general.change_status')}</option>
              </Form.Select>
              <Button
                onClick={handleBulkActions}
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                {t('general.apply')}
              </Button>
            </div>
          ) : (
            <div id="announcements-actions" className="d-sm-flex gap-3">
              <IconButton
                variant="link"
                size="sm"
                icon="download"
                transform="shrink-3"
                onClick={handleShowExportModal}
              >
                <span className="d-none d-sm-inline-block">
                  {t('general.export')}
                </span>
              </IconButton>
              <IconButton
                variant="primary"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={handleAddButton}
              >
                <span className="d-sm-inline-block">{`${t('general.add')} ${t(
                  'orders.customer'
                ).toLocaleLowerCase()}`}</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>

      {/* Export data modal */}
      <ExportCustomerModal
        visible={showExportModal}
        onHide={() => setShowExportModal(false)}
        current_items={count}
      />
    </>
  );
};

UserListHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  selectedFlatRows: PropTypes.array,
  mutateAsync: PropTypes.func,
  refetch: PropTypes.func,
  count: PropTypes.number
};
