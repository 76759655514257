import SoftBadge from 'components/common/SoftBadge';
import { PaymentStatuses } from 'constants/orders';
import { t } from 'i18next';

const PaymentStatus = ({ status = 'unpaid', className }) => {
  return (
    <SoftBadge pill bg={PaymentStatuses?.[status]?.bg} className={className}>
      {t(PaymentStatuses?.[status]?.label)}
    </SoftBadge>
  );
};

export default PaymentStatus;
