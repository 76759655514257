import { useQueryClient } from '@tanstack/react-query';
import { useWriteOrderNote } from 'apis/OrdersApi';
import IconFA from 'components/common/IconFA';
import LoadingButton from 'components/common/buttons/LoadingButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const Comment = React.memo(({ note }) => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const [text, setText] = useState(note);
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: writeNote, isPending } = useWriteOrderNote();
  const queryClient = useQueryClient();
  const onSave = () => {
    writeNote(
      { _id: orderId, note: text },
      {
        onSuccess: () => {
          setText(text);
          setIsEditing(false);
          queryClient.invalidateQueries({ queryKey: ['useGetOrders'] });
        }
      }
    );
  };
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex flex-row w-100 justify-content-between align-items-center">
          <h5>{t('orders.order_comments')}</h5>
          {isEditing ? (
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => setIsEditing(false)}
                className="me-2"
              >
                <span>{t('general.cancel')}</span>
              </Button>
              <LoadingButton
                isLoading={isPending}
                size="sm"
                variant="primary"
                onClick={onSave}
              >
                <span>{t('general.save')}</span>
              </LoadingButton>
            </div>
          ) : (
            <Button
              size="sm"
              variant="falcon-default"
              className=""
              onClick={() => setIsEditing(true)}
            >
              {t('general.edit')}
            </Button>
          )}
        </div>
        {!isEditing && !note ? null : isEditing ? (
          <InputGroup className="mt-2">
            <FormControl
              value={text}
              onChange={e => setText(e?.target?.value)}
              placeholder={t('orders.enter_order_note')}
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        ) : (
          <p className="fs--1 mb-0">{note}</p>
        )}
      </Card.Body>
    </Card>
  );
});

Comment.propTypes = {
  data: PropTypes.string.isRequired
};
