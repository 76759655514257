import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingButton from './buttons/LoadingButton';

/**
 * @param {bool} remove A boolean value to determine if the modal is for removing or deleting an item.
 * @param {string} name The name of the item to be deleted.
 * @param {bool} onShow A boolean value to detemine if the modal should be shown.
 * @param {func} onClose A function handle closing modal.
 * @param {func} onDelete A function handle deleting item.
 * @param {boolean} isLoading A boolean to determine if action is executing
 * @returns
 */
const DeleteWarningModal = forwardRef(
  (
    {
      remove,
      name = 'this item',
      onShow,
      onClose: onModalClose,
      onDelete,
      isLoading = false,
      title
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [internalShow, setInternalShow] = useState(false);
    const [product, setProduct] = useState(null);
    useImperativeHandle(ref, () => ({
      show,
      hide
    }));
    const show = product => {
      setInternalShow(true);
      setProduct(product);
    };
    const hide = product => {
      setInternalShow(false);
    };
    const action = (() => {
      if (remove && isLoading) return t('general.removing');
      if (remove && !isLoading) return t('general.remove');
      if (!remove && isLoading) return t('general.deleting');
      if (!remove && !isLoading) return t('general.delete');
    })();
    const onClose = () => {
      setInternalShow(false);
      onModalClose?.();
    };
    return (
      <Modal show={internalShow || onShow} onHide={onClose} centered>
        <Modal.Header>
          {title ? (
            <Modal.Title>{title}</Modal.Title>
          ) : (
            <Modal.Title>
              {remove ? t('general.remove') : t('general.delete')}{' '}
              {product?.name || name}?
            </Modal.Title>
          )}
          <FalconCloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          {t('general.delete_confirm')}{' '}
          {remove
            ? t('general.remove').toLowerCase()
            : t('general.delete').toLowerCase()}{' '}
          <strong className="text-danger">{product?.name || name}</strong>
          {t('general.cant_undone')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            transform="shrink-3"
            onClick={onClose}
          >
            {t('general.cancel')}
          </Button>
          <LoadingButton
            variant="danger"
            isLoading={isLoading}
            onClick={() => onDelete?.(product)}
            size="md"
          >
            <span>{action}</span>
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    );
  }
);

DeleteWarningModal.propTypes = {
  name: PropTypes.string,
  onShow: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  remove: PropTypes.bool
};

export default DeleteWarningModal;
