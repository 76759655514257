import { useQueryClient } from '@tanstack/react-query';
import { useDeletePackage, useGetPackages } from 'apis/PackagesApi';
import { useGetSellerProfile, useUpdateSellerProfile } from 'apis/SellersApi';
import { AddEditModal } from 'components/common/AddEditModal';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import TableFooter from 'components/common/advance-table/TableFooter';
import TableWrapper from 'components/common/advance-table/TableWrapper';
import LoadingButton from 'components/common/buttons/LoadingButton';
import CardDropdown from 'components/common/CardDropdown';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import IconButton from 'components/common/IconButton';
import SelectInput from 'components/common/inputs/SelectInput';
import { PlaceholderTable } from 'components/common/PlaceHolder';
import SoftBadge from 'components/common/SoftBadge';
import { DEFAULT_PAGE, DEFAULT_PAGE_LIMIT } from 'constants/constants';
import { useAuth } from 'contexts/AuthContext';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PackageDetail from './PackageDetail';
import { imageUrlToId } from '../../dashboard/components/helpers';

export const getPackageTypeName = title => {
  switch (title) {
    case 'box':
      return t('Box');
    case 'envelope':
      return t('Envelope');
    default:
      return t('Box');
  }
};

export const PackagesList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    addPackage
  }));
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { params, handleParamsChange } = useURLParams();
  const limit = parseInt(params?.limit) || DEFAULT_PAGE_LIMIT;
  const page = parseInt(params?.page) || DEFAULT_PAGE;
  const handlePaginationChange = pagination => {
    handleParamsChange({ ...pagination, page: pagination?.page + 1 });
  };
  const { mutate: deletePackage } = useDeletePackage();
  const { data: profileResponse, isSuccess: isSellerSuccess } =
    useGetSellerProfile();
  const { mutate: updateProfile, isPending } = useUpdateSellerProfile();

  const seller = profileResponse?.data;
  const isSeller = user?.role === 'seller';
  const isFBN = seller?.fulfill_type === 'nailzy';
  const isSellerFulfillType = seller?.fulfill_type === 'seller';

  const {
    data: res,
    isFetching,
    isSuccess
  } = useGetPackages({
    ...params,
    page: page,
    limit: limit,
    fulfill_type: isSeller ? undefined : 'nailzy'
  });
  const data = res?.data?.results || [];
  const total = res?.data?.total || 0;
  const packageOptions = data?.map(item => ({
    value: item._id,
    label: `${item.name}: ${item.length} x ${item.width} x ${item.height} ${item.unit}, ${item.weight} ${item.weight_unit}`
  }));

  const [selectedPackage, setSelectedPackage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(false);
  const [selectedDefaultPackage, setSelectedDefaultPackage] = useState();

  const addPackage = () => {
    setSelectedPackage({});
    setShowModal(true);
  };
  const editPackage = data => {
    setSelectedPackage(data);
    setShowModal(true);
  };
  const onDelete = () => {
    deletePackage(packageToDelete?._id, {
      onSuccess: () => {
        toast.success(t('general.delete_success'));
        setPackageToDelete(null);
        setTimeout(() => {
          queryClient.invalidateQueries(['useGetPackages']);
        }, 300);
      }
    });
  };
  const handleSelectedDefaultPackage = value => {
    setSelectedDefaultPackage(value);
  };
  const handleSaveDefaultPackage = () => {
    const clonedSeller = structuredClone(seller);
    clonedSeller.logo_id = imageUrlToId(clonedSeller?.logo_url);
    clonedSeller.banner_ids = clonedSeller.banner_urls?.map(x =>
      imageUrlToId(x)
    );
    delete clonedSeller?.logo_url;
    delete clonedSeller?.banner_urls;
    delete clonedSeller?.updated_on;
    delete clonedSeller?.created_on;
    updateProfile(
      {
        ...clonedSeller,
        default_package_id: selectedDefaultPackage?.value
      },
      {
        onSuccess: () => {
          toast.success(t('general.update_success'));
        },
        onError: () => {
          toast.error(t('general.update_fail'));
        }
      }
    );
  };
  const handleResetDefaultPackage = () => {
    setSelectedDefaultPackage(
      packageOptions?.find(option => option._id === seller?.default_package_id)
    );
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('packages.package_name'),
        Cell: rowData => {
          const { name, is_default } = rowData.row.original;
          return (
            <Link
              className="text-primary"
              onClick={() => editPackage(rowData?.row?.original)}
            >
              {name}{' '}
              {is_default ? (
                <SoftBadge bg="dark" pill className="ms-2">
                  {t('general.default')}
                </SoftBadge>
              ) : null}
            </Link>
          );
        }
      },
      {
        accessor: row => (row.type ? getPackageTypeName(row.type) : '--'),
        Header: t('general.type')
      },
      {
        accessor: row =>
          `${row?.length || 0} x ${row?.width || 0} x ${row?.height || 0} ${
            row?.unit || ''
          }`,
        Header: t('orders.size')
      },
      {
        accessor: row => `${row?.weight || 0} ${row?.weight_unit || ''}`,
        Header: t('products.weight')
      },
      {
        id: 'action',
        disableSortBy: true,
        cellProps: { className: 'text-end' },
        Cell: rowData => {
          return isFBN ? null : (
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item
                  onClick={event => editPackage(rowData?.row?.original)}
                >
                  {t('general.edit')}
                </Dropdown.Item>

                <Dropdown.Divider as="div" />
                <Dropdown.Item
                  onClick={event => setPackageToDelete(rowData?.row?.original)}
                  className="text-danger"
                >
                  {t('general.delete')}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          );
        }
      }
    ],
    []
  );

  const initDefaultPackage = useCallback(() => {
    if (!isSuccess || !isSellerSuccess) return;
    if (!packageOptions?.length) return;
    if (selectedDefaultPackage) return;
    setSelectedDefaultPackage(
      packageOptions?.find(
        option => option.value === seller?.default_package_id
      )
    );
  }, [isSuccess, isSellerSuccess]);

  useEffect(() => {
    initDefaultPackage();
  }, [initDefaultPackage]);

  if (isFetching) return <PlaceholderTable />;
  return (
    <Row className="g-3">
      <Col xs={12} md={isSellerFulfillType ? 8 : 12}>
        <Card>
          <Card.Header>
            <Row>
              <Col className="flex-1">
                <Card.Title>
                  <h4>{t('packages.packages')}</h4>
                </Card.Title>
                <span className="fs--1">{t('packages.packages_decs')}</span>
              </Col>
              <Col md={'auto'}>
                {isFBN ? null : (
                  <IconButton
                    variant="primary"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    onClick={() => {
                      addPackage();
                    }}
                  >
                    <span className="d-sm-inline-block">{`${t(
                      'general.add'
                    )} ${t('packages.package').toLocaleLowerCase()}`}</span>
                  </IconButton>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <TableWrapper
              columns={columns}
              data={data}
              pageSize={limit}
              pageIndex={page - 1}
              handlePaginationChange={handlePaginationChange}
              pageCount={Math.ceil(total / limit)}
            >
              {data?.length ? (
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              ) : (
                <div className="h5 m-3 text-center">{t('general.no_data')}</div>
              )}
              <Card.Footer>
                <TableFooter
                  pageSize={limit}
                  rowCount={total}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
                {/* Add/edit modal */}
                <AddEditModal
                  onHide
                  value={selectedPackage}
                  setValue={setSelectedPackage}
                  show={!!showModal}
                  setShow={setShowModal}
                  size="lg"
                  ValueForm={PackageDetail}
                />

                <DeleteWarningModal
                  name={`${packageToDelete?.name}`}
                  onShow={!!packageToDelete}
                  onClose={() => setPackageToDelete(null)}
                  onDelete={onDelete}
                />
              </Card.Footer>
            </TableWrapper>
          </Card.Body>
        </Card>
      </Col>

      {isSellerFulfillType && (
        <Col xs={12} md={4}>
          <Card>
            <Card.Header>
              <Card.Title>
                <h5>{t('packages.default_package')}</h5>
              </Card.Title>
              <span className="fs--1">
                {t('packages.default_package_label')}
              </span>
            </Card.Header>
            <Card.Body className="bg-100">
              <SelectInput
                options={packageOptions}
                value={selectedDefaultPackage}
                onChange={handleSelectedDefaultPackage}
                placeholder="Select..."
              />
            </Card.Body>
            <Card.Footer>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="falcon-default"
                  onClick={handleResetDefaultPackage}
                >
                  {t('general.reset')}
                </Button>
                <LoadingButton
                  disabled={!selectedDefaultPackage}
                  isLoading={isPending}
                  type="button"
                  variant="primary"
                  onClick={handleSaveDefaultPackage}
                >
                  {t('general.save')}
                </LoadingButton>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      )}
    </Row>
  );
});
export default PackagesList;
