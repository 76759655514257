import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../../../../helpers/utils';

export const ShippingMethod = ({ data }) => {
  const { t } = useTranslation();
  const provider = data?.provider === 'nailzy' ? '' : data?.provider;
  const serviceLevel = data?.servicelevel_name;
  const estimatedDays = data?.estimated_days;
  const amount = data?.amount;

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5>{t('orders.shipping_method')}</h5>
        {data ? (
          <div className="fs--1 text-black">
            <p className="text-700">{t('orders.customer_selected_method')}</p>
            <p>
              {provider} {serviceLevel}
            </p>
            <p>{`${estimatedDays} ${t('orders.business_days')}`}</p>
            <p>{currencyFormatter.format(amount)}</p>
          </div>
        ) : (
          <small>{t('general.no_data')}</small>
        )}
      </Card.Body>
    </Card>
  );
};
