import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useURLParams } from 'hooks/useURLParams';
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SortDropdownButton = ({ sortArray, isDisabled }) => {
  const { t } = useTranslation();

  const { params, handleParamChange } = useURLParams();

  const handleOnClick = value => () => {
    const sortValue = params.sort === value ? null : value;
    handleParamChange('sort')(sortValue);
  };

  return (
    <DropdownButton
      disabled={isDisabled}
      id="sort"
      align="end"
      title={['fs--1 ms-1', 'd-none d-sm-inline-block ms-1'].map((element, i) =>
        i === 0 ? (
          <FontAwesomeIcon
            key={i}
            icon={faSortAmountDown}
            className={element}
          />
        ) : (
          <span key={i} className="d-none d-sm-inline-block ms-1">
            {t('general.sort')}
          </span>
        )
      )}
      variant="falcon-default"
      size="sm"
    >
      {sortArray.map((route, i) => (
        <Dropdown.Item
          key={i}
          onClick={handleOnClick(route?.path)}
          active={route?.path === params?.sort}
        >
          {route?.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default SortDropdownButton;
