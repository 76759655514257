export const ShippingInformationTypeEnum = {
  Shippo: 'shippo',
  Manual: 'manual',
  Custom: 'custom'
};

export const OrderStatusEnum = {
  ALL: '',
  PENDING: 'pending',
  PROCESSING: 'processing',
  PARTIALLY_SHIPPED: 'partially_shipped',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  FAILED_DELIVERY: 'failed_delivery',
  DRAFT: 'draft'
};
export const OrderPaymentStatusEnum = {
  ALL: '',
  UNPAID: 'unpaid',
  PARTIALLY_PAID: 'partially_paid',
  PAID: 'paid',
  REFUNDED: 'refunded',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAYMENT_FAILED: 'payment_failed'
};

export const PaymentStatuses = {
  [OrderPaymentStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: ''
  },
  [OrderPaymentStatusEnum.UNPAID]: {
    label: 'orders.unpaid',
    bg: 'secondary'
  },
  [OrderPaymentStatusEnum.PARTIALLY_PAID]: {
    label: 'orders.partially_paid',
    bg: 'warning'
  },
  [OrderPaymentStatusEnum.PAID]: {
    label: 'orders.paid',
    bg: 'secondary'
  },
  [OrderPaymentStatusEnum.REFUNDED]: {
    label: 'orders.refunded',
    bg: 'secondary'
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment',
    bg: 'danger'
  },
  [OrderPaymentStatusEnum.PAYMENT_FAILED]: {
    label: 'orders.payment_failed',
    bg: 'danger'
  }
};

export const OrderStatusStatuses = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: ''
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending',
    bg: 'warning',
    icon: 'clock'
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing',
    bg: 'warning',
    icon: 'rotate'
  },
  [OrderStatusEnum.PARTIALLY_SHIPPED]: {
    label: 'orders.order_partially_shipped',
    bg: 'warning',
    icon: 'truck'
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped',
    bg: 'secondary',
    icon: 'truck'
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered',
    bg: 'secondary',
    icon: 'check-circle'
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_complete',
    bg: 'success',
    icon: 'circle-check'
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled',
    bg: 'secondary',
    icon: 'ban'
  },
  [OrderStatusEnum.DRAFT]: {
    icon: 'save',
    bg: 'info',
    label: 'general.draft'
  }
};

export const FilterTabStatuses = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all'
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment'
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending'
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing'
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped'
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered'
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_complete'
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled'
  }
};

export const CancelReason = {
  out_of_stock: 'orders.out_of_stock',
  customer_request: 'orders.customer_request',
  payment_error: 'orders.payment_error',
  other: 'orders.other',
  abandoned_checkout: 'orders.abandoned_checkout'
};
export const SHIPMENT_TYPE = {
  MANUAL: 'manual',
  SHIPPO: 'shippo',
  CUSTOM: 'custom'
};
export const SHIPMENT_TYPE_LABEL = {
  MANUAL: 'orders.shipment_manual',
  SHIPPO: 'orders.shipment_shippo',
  CUSTOM: 'orders.shipment_custom'
};
