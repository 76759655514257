import dayjs from 'dayjs';
import {
  DATE_TIME_FORMAT_VI,
  DEFAULT_DATE_TIME_FORMAT
} from 'constants/constants';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import { getI18n } from 'react-i18next';

export const formatDate = date => {
  const locale = getI18n().language;

  let format;
  switch (locale) {
    case 'vi':
      format = DATE_TIME_FORMAT_VI;
      break;
    case 'en':
    default:
      format = DEFAULT_DATE_TIME_FORMAT;
      break;
  }

  return dayjs.unix(date).locale(locale).format(format);
};
