import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
import Notification from 'components/notification/Notification';
import { useGetNewSellersNotifications } from 'apis/NotificationsApi';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

const NotificationDropdown = React.memo(() => {
  const { data, isLoading, error } = useGetNewSellersNotifications(
    {},
    'getNotifications'
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isIterableArray(data?.data) && data?.data?.length > 0) {
      setIsAllRead(false);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon={faBell} transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          {!isLoading && error && null}
          {!isLoading && data?.status && (
            <ListGroup
              variant="flush"
              className="fw-normal fs--1 scrollbar"
              style={{ maxHeight: '19rem' }}
            >
              <div className="list-group-title">NEW SELLERS</div>{' '}
              {isIterableArray(data?.data) &&
                data?.data?.map(notification => (
                  <ListGroup.Item key={notification._id} onClick={() => null}>
                    <Notification {...notification} flush />
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
          {/* <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="#!">
              View all
            </Link>
          </div> */}
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default NotificationDropdown;
