import React, { useState } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
import {
  Col,
  Row,
  Form,
  Button,
  Modal,
  FormGroup,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LoadingButton from 'components/common/buttons/LoadingButton';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { getPackageValidation } from './yup-validataion-schemas';
import { getPackageTypeName } from './PackagesList';
import { useCreatePackage, useUpdatePackage } from 'apis/PackagesApi';
import { toast } from 'react-toastify';
import { useGetSellerProfile } from 'apis/SellersApi';

const PackageDetail = ({ value, handleClose }) => {
  const queryClient = useQueryClient();
  const isEdit = !!value?._id;

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      length: value?.length || 0,
      width: value?.width || 0,
      height: value?.height || 0,
      weight: value?.weight || 0,
      type: value?.type || 'box',
      unit: value?.unit || 'in',
      weight_unit: value?.weight_unit || 'lb',
      is_default: value?.is_default || false
    },
    resolver: yupResolver(getPackageValidation())
  });
  const type = watch('type');
  const is_default = watch('is_default');
  const [saving, setSaving] = useState(false);
  const { mutate: updatePackage, isLoading: updating } = useUpdatePackage();
  const { mutate: createPackage, isLoading: creating } = useCreatePackage();

  const onError = async err => {};
  const onSave = async inputData => {
    setSaving(true);
    const {
      name,
      type,
      length,
      width,
      height,
      unit,
      weight,
      weight_unit,
      is_default
    } = inputData;

    let submitData = {
      name,
      type,
      length,
      width,
      height,
      unit,
      weight,
      weight_unit,
      is_default
    };

    const onFinish = () => {
      reset();
      setSaving(false);
      handleClose();
      setTimeout(() => {
        queryClient.invalidateQueries(['useGetPackages']);
      }, 300);
    };

    if (isEdit) {
      updatePackage(
        { ...submitData, _id: value?._id },
        {
          onSuccess: () => {
            toast.success(t('general.update_success'));
            onFinish();
          }
        }
      );
    } else {
      createPackage(submitData, {
        onSuccess: () => {
          toast.success(t('general.create_success'));
          onFinish();
        }
      });
    }
  };

  const isLoading = creating || updating;

  const { data: seller } = useGetSellerProfile();
  const isFBN = seller?.data?.fulfill_type === 'nailzy';

  return (
    <>
      <Form onSubmit={handleSubmit(onSave, onError)}>
        <Modal.Header>
          <Modal.Title>
            {t(isEdit ? 'packages.edit_package' : 'packages.add_package')}
          </Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center">
            <Col md={12}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>
                  {t('packages.package_name')}{' '}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Large box"
                  defaultValue={value?.name}
                  isInvalid={!!errors.name}
                  name="name"
                  {...register('name')}
                  disabled={isFBN}
                />
                <Form.Text className="text-danger">
                  {errors.name && errors.name.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="type">
                <Form.Label>
                  {t('general.type')} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={type}
                  size="md"
                  className="me-2"
                  onChange={e => {
                    setValue('type', e?.target?.value);
                  }}
                  disabled={isFBN}
                >
                  {['box', 'envelope'].map((key, index) => (
                    <option key={key} value={key}>
                      {getPackageTypeName(key)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <FormGroup className="mb-3" controlId="dimension">
                <Form.Label>
                  {t('products.dimensions_lwh')}{' '}
                  <span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <FormControl
                    type="number"
                    step="0.1"
                    name="length"
                    defaultValue={value?.length || 0}
                    min={0}
                    isInvalid={!!errors?.length}
                    onChange={e => {
                      setValue('length', e.target.value);
                    }}
                    disabled={isFBN}
                  />
                  <FormControl
                    type="number"
                    step="0.1"
                    name="width"
                    defaultValue={value?.width || 0}
                    min={0}
                    isInvalid={!!errors?.width}
                    onChange={e => {
                      setValue('width', e.target.value);
                    }}
                    disabled={isFBN}
                  />
                  <FormControl
                    type="number"
                    step="0.1"
                    name="height"
                    defaultValue={value?.height || 0}
                    min={0}
                    isInvalid={!!errors?.height}
                    onChange={e => {
                      setValue('height', e.target.value);
                    }}
                    disabled={isFBN}
                  />
                  <Form.Select
                    defaultValue={value?.unit || 'in'}
                    onChange={e => {
                      setValue('unit', e.target.value);
                    }}
                    disabled={isFBN}
                  >
                    <option value="in">in</option>
                    <option value="ft">ft</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.length?.message ||
                      errors?.width?.message ||
                      errors?.height?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="mb-3" controlId="weight">
                <Form.Label>
                  {t('products.weight')} <span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <FormControl
                    type="number"
                    step="0.1"
                    name="weight"
                    defaultValue={value?.weight || 0}
                    min={0}
                    isInvalid={!!errors?.weight}
                    onChange={e => {
                      setValue('weight', e.target.value);
                    }}
                    disabled={isFBN}
                  />
                  <Form.Select
                    defaultValue={value?.weight_unit || 'lb'}
                    onChange={e => {
                      setValue('weight_unit', e.target.value);
                    }}
                    disabled={isFBN}
                  >
                    <option value="lb">lb</option>
                    <option value="oz">oz</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.weight?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="is_default">
                <Form.Check
                  type="switch"
                  label={t('general.make_as_default')}
                  onChange={e => {
                    setValue('is_default', e.target.checked);
                  }}
                  checked={is_default}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            {t('general.close')}
          </Button>
          <LoadingButton
            type="submit"
            isLoading={saving || isLoading}
            disabled={isFBN}
          >
            {t(isEdit ? 'general.save' : 'general.create')}
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </>
  );
};
export default PackageDetail;
