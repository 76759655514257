import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/common/buttons/LoadingButton';
import { useGetCategories } from 'apis/CategoriesApi';
import IconFA from 'components/common/IconFA';
import SelectInput from 'components/common/inputs/SelectInput';
import FormatSelectOptionLabelWithStatus from 'components/common/inputs/FormatSelectOptionLabel';
import { useGetBrands } from 'apis/BrandsApi';
import { useGetSellers } from 'apis/SellersApi';
import ProductItem from './ProductItem';
import CheckBox from 'components/common/checkbox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useAsyncDebounce } from 'react-table';
import { useAdminProductsMarketplace } from 'apis/DraftCartApi';
import vi from 'dayjs/locale/vi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectProductModal = ({
  onHide,
  visible,
  onSave,
  selectedProducts: selectedProductsProps
}) => {
  const LIMIT = 10;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({ page: 1, limit: LIMIT });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { page, limit } = filters || {};
  useEffect(() => {
    setSelectedProducts(selectedProductsProps || []);
  }, [selectedProductsProps]);
  // PRODUCT
  const customerFilter = useMemo(
    () => ({
      page: filters?.page || 1,
      limit: filters?.limit || LIMIT,
      search: filters?.name || undefined,
      category_ids: filters?.category_id ? [filters?.category_id] : undefined,
      seller_ids: filters?.seller_id ? [filters?.seller_id] : undefined,
      brand_ids: filters?.brand_id ? [filters?.brand_id] : undefined
    }),
    [filters]
  );
  const { products, loading, total } = useAdminProductsMarketplace(
    customerFilter,
    visible
  );
  useAdminProductsMarketplace(
    {
      ...customerFilter,
      page: filters?.page + 1
    },
    visible
  );
  useAdminProductsMarketplace(
    {
      ...customerFilter,
      page: filters?.page + 2
    },
    visible
  );

  useEffect(() => {
    debounceSearch(search);
  }, [search]);
  const debounceSearch = useAsyncDebounce(value => {
    setFilters({ ...filters, name: search || undefined });
  }, 500);

  const onClickSave = () => {
    onSave?.(selectedProducts);
    onHide?.();
  };

  // CAT
  const { data: getCateResponse } = useGetCategories({}, visible);
  const cates = getCateResponse?.data || [];
  const categoryOptions = cates.map(cat => ({
    value: cat._id,
    label: cat.name,
    status: cat.status
  }));

  //BRAND
  const { data: getBrandsResponse } = useGetBrands(
    { limit: 1000 },
    '',
    visible
  );
  const brands = getBrandsResponse?.data?.results || [];
  const brandOptions =
    brands?.map(b => ({
      value: b._id,
      label: b.name,
      status: b.status
    })) || [];

  // SELLER
  const { data: getSellersResponse } = useGetSellers({ limit: 1000 }, visible);
  const sellers = getSellersResponse?.data?.results || [];
  const sellersOptions = sellers
    ?.map(seller => ({
      value: seller._id,
      label: seller.store_name,
      status: seller.status
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const onSelect = (product, checked = true) => {
    let array = [...selectedProducts];
    array = array?.filter(x => x?._id !== product?._id);
    if (checked && product?.variants?.length) {
      array.push(product);
    }
    setSelectedProducts(array);
  };
  const onSelectAll = checked => {
    let array = [...selectedProducts];

    for (let i = 0; i < products.length; i++) {
      const prod = products[i];
      array = array?.filter(x => x?._id !== prod?._id);
      if (checked) {
        array.push({
          ...prod,
          variants: (prod?.variants || [])?.filter(x => x?.available_stock > 0)
        });
      }
    }
    setSelectedProducts(array);
  };
  const isSelectedAll = useMemo(() => {
    if (selectedProducts?.length >= products?.length) {
      let array = [...selectedProducts];
      array = array?.filter(x => !!products?.find(y => x?._id === y?._id));
      if (array?.length >= products?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [products, selectedProducts]);
  const totalItems = useMemo(() => {
    return selectedProducts?.reduce(
      (p, i) => p + (i?.variants?.length || 0),
      0
    );
  }, [selectedProducts]);
  return (
    <Modal show={visible} onHide={onHide} centered size="xl">
      <Modal.Header>
        <Modal.Title>{`${t('promotions.select_products')} `}</Modal.Title>
        <FalconCloseButton onClick={onHide} />
      </Modal.Header>

      <Modal.Body>
        <div>
          <Row className="mb-3">
            <Col md={3}>
              <div className="mb-2" style={{ fontSize: 14, fontWeight: '600' }}>
                {t('promotions.find_by_name')}
              </div>
              <InputGroup className="">
                <InputGroup.Text id="basic-addon1">
                  <IconFA className={'search'} />
                </InputGroup.Text>
                <Form.Control
                  placeholder={t('general.search')}
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={e => {
                    setSearch(e?.target?.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col md={3}>
              <div className="mb-2" style={{ fontSize: 14, fontWeight: '600' }}>
                {t('promotions.find_by_cate')}
              </div>
              <SelectInput
                closeMenuOnSelect={true}
                options={categoryOptions}
                placeholder={t('products.select_category')}
                onChange={cate => {
                  setFilters(prev => ({
                    ...prev,
                    category_id: cate?.value,
                    page: 1
                  }));
                }}
                required
                isClearable
                classNamePrefix="react-select"
                formatOptionLabel={FormatSelectOptionLabelWithStatus}
              />
            </Col>
            <Col md={3}>
              <div className="mb-2" style={{ fontSize: 14, fontWeight: '600' }}>
                {t('promotions.find_by_brand')}
              </div>
              <SelectInput
                closeMenuOnSelect={true}
                options={brandOptions}
                placeholder={t('products.select_brand')}
                onChange={brand => {
                  setFilters(prev => ({
                    ...prev,
                    brand_id: brand?.value,
                    page: 1
                  }));
                }}
                required
                isClearable
                classNamePrefix="react-select"
                formatOptionLabel={FormatSelectOptionLabelWithStatus}
              />
            </Col>
            <Col md={3}>
              <div className="mb-2" style={{ fontSize: 14, fontWeight: '600' }}>
                {t('promotions.find_by_seller')}
              </div>
              <SelectInput
                closeMenuOnSelect={true}
                options={sellersOptions}
                placeholder={t('products.select_seller')}
                onChange={seller => {
                  setFilters(prev => ({
                    ...prev,
                    seller_id: seller?.value,
                    page: 1
                  }));
                }}
                required
                isClearable
                classNamePrefix="react-select"
                formatOptionLabel={FormatSelectOptionLabelWithStatus}
              />
            </Col>
          </Row>
        </div>

        <div className="px-3">
          <Row className="py-1 bg-100">
            <Col md={6}>
              <div className="d-flex flex-row gap-3">
                <CheckBox
                  checked={isSelectedAll}
                  onChange={checked => onSelectAll(checked)}
                />
                <div className="fw-semi-bold fs-0">{t('orders.product')}</div>
              </div>
            </Col>
            <Col md={2}>
              <div className="fw-semi-bold fs-0">{t('orders.sku')}</div>
            </Col>
            <Col md={2}>
              <div className="fw-semi-bold fs-0">
                {t('promotions.available_stock')}
              </div>
            </Col>
            <Col md={2}>
              <div className="fw-semi-bold fs-0">{t('products.price')}</div>
            </Col>
          </Row>
        </div>
        <div style={{ height: '500px', overflowY: 'auto' }}>
          {loading ? (
            <div className="d-flex w-100 justify-content-center my-4">
              <FontAwesomeIcon
                spin
                icon="spinner"
                size="2x"
                className="text-primary"
              />
              {/* <Spinner size="sm" animation="border" variant="secondary" /> */}
            </div>
          ) : null}
          {products?.length ? (
            products?.map((x, i) => {
              return (
                <ProductItem
                  selectedProducts={selectedProducts}
                  product={x}
                  onChange={onSelect}
                  key={`product_${i}`}
                />
              );
            })
          ) : loading ? null : (
            <div className="h5 m-3 text-center">{t('general.no_data')}</div>
          )}
        </div>
        {total > limit ? (
          <div className="mt-3 border-top pt-3">
            <AdvanceTableFooter
              rowCount={total}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={limit}
              perPage={limit}
              pageIndex={page - 1}
              page={{ length: limit }}
              canNextPage={page * limit < total}
              canPreviousPage={page > 1}
              setPageSize={l => setFilters(prev => ({ ...prev, limit: l }))}
              nextPage={() => setFilters(prev => ({ ...prev, page: page + 1 }))}
              previousPage={() =>
                setFilters(prev => ({ ...prev, page: page - 1 }))
              }
              nextText={t('general.next')}
              previousText={t('general.previous')}
            />
          </div>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="falcon-default" onClick={onHide}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          variant="primary"
          //   isLoading={isLoading}
          disabled={!totalItems}
          onClick={onClickSave}
          size="md"
        >
          <span>{t('orders.select_items', { total: totalItems })}</span>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};
export default SelectProductModal;
