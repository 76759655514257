import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PackagesList from './components/PackagesList';

const PackagesPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('packages.packages'));

  return (
    <>
      <Row className="my-3 gap-3">
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: '/' }}
              className="fs--1"
            >
              <FontAwesomeIcon icon={faHome} /> {t('navigation.home')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="fs--1">
              {t('packages.packages')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col xs={12}>
          <PackagesList />
        </Col>
      </Row>
    </>
  );
};

export default PackagesPage;
