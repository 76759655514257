import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/pro-solid-svg-icons';

const Notification = ({ _id, store_name, lname, name }) => (
  <Link
    className={classNames('notification notification-flush')}
    to={`/sellers/${_id}`}
  >
    <div className="notification-avatar">
      <Avatar name={`${name} ${lname}`} className="me-3" />
    </div>

    <div className="notification-body">
      <p
        className="mb-1 h6 text-primary"
        dangerouslySetInnerHTML={createMarkup(`${name} ${lname}`)}
      />
      <span className="notification-time">
        <FontAwesomeIcon icon={faStore} /> {store_name}
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  store_name: PropTypes.string,
  lname: PropTypes.string,
  name: PropTypes.string
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
