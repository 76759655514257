import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import { customerKeys } from 'query-keys/customer-keys';
import queryString from 'query-string';
import { apiCallV2 } from './ApiCall';

const config = dataFetchingQueryConfig;
const API_END_POINT = 'admin/customers';

export const useGetCustomers = (params = {}) => {
  const query = queryString.stringify(params);
  const url = `${API_END_POINT}?${query ? query : ''}`;

  return useQuery({
    queryKey: customerKeys.list(params),
    queryFn: () => apiCallV2({ url }),
    ...config
  });
};

export const useGetInfiniteCustomers = () => {
  const LIMIT = 25;

  const queryFn = ({ pageParam }) =>
    apiCallV2({ url: `${API_END_POINT}?limit=${LIMIT}&page=${pageParam}` });

  const getNextPageParam = (lastPage, pages) => {
    const totalPage = lastPage.data.total / LIMIT;
    const currentPage = pages.length;
    return totalPage > currentPage ? currentPage + 1 : null;
  };

  return useInfiniteQuery({
    queryKey: customerKeys.infinite(),
    queryFn,
    initialPageParam: 1,
    getNextPageParam
  });
};

export const useCustomerDetails = id => {
  const url = 'admin/customers/' + id;

  return useQuery({
    queryKey: ['useCustomerDetails', id],
    queryFn: () => apiCallV2({ url, method: 'get' })
  });
};

export const useExportCustomers = (params = {}, key) => {
  const url = '/admin/customers/exports';
  return useQuery({
    queryKey: ['useExportCustomers', params],
    enabled: false,
    queryFn: () =>
      apiCallV2({
        url,
        params
      })
  });
};
export const useCreateCustomerAddress = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { customer_id, ...els } = data;
      const url = `admin/customers/${customer_id}/address`;
      return apiCallV2({
        url,
        data: els,
        method: 'POST'
      });
    }
  });
};
export const useUpdateCustomerAddress = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { customer_id, ...els } = data;
      const url = `admin/customers/${customer_id}/address`;
      return apiCallV2({
        url,
        data: els,
        method: 'PUT'
      });
    }
  });
};

export const useGetReferralHistory = customerId => {
  const url = `${API_END_POINT}?referrer_id=${customerId}`;
  return useQuery({
    queryKey: customerKeys.referralHistory(customerId),
    queryFn: () => apiCallV2({ url }),
    enabled: !!customerId,
    ...config
  });
};

export const useDeleteCustomer = () => {
  return useMutation({
    mutationFn: ({ id, reason }) => {
      const url = 'admin/customers/' + id;
      return apiCallV2({
        url,
        method: 'delete',
        data: { deleted_reason: reason }
      });
    }
  });
};
export const useSendEmailResetPass = () => {
  return useMutation({
    mutationFn: id => {
      const url = `admin/customers/${id}/reset-password`;
      return apiCallV2({
        url,
        method: 'put'
      });
    }
  });
};
