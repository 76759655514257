import {
  useMutation,
  useQuery,
  useQueryClient,
  queryOptions,
  QueryClient,
  useInfiniteQuery
} from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import { useGetLogin } from 'hooks/useGetLogin';
import { t } from 'i18next';
import { orderKeys } from 'query-keys/order-keys';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { apiCall, apiCallV2 } from './ApiCall';

const config = dataFetchingQueryConfig;
const API_END_POINT = 'admin/orders';
const queryClient = new QueryClient();

export const orderListOptions = params => {
  return queryOptions({});
};

export const useGetOrders = (params = {}, key) => {
  // const
  const url = `${API_END_POINT}?${queryString.stringify(params)}`;
  return useQuery({
    queryKey: ['useGetOrders', params, key],
    queryFn: () =>
      apiCallV2({
        url
      }),
    ...config
  });
};

export const useGetInfiniteOrders = initPage => {
  const LIMIT = 25;

  const queryFn = async ({ pageParam }) => {
    const response = await apiCallV2({
      url: `${API_END_POINT}?limit=${LIMIT}&page=${pageParam}`
    });
    return {
      results: response?.data?.results,
      total: response?.data?.total
    };
  };

  const getNextPageParam = (lastPage, pages) => {
    const totalPage = lastPage.total / LIMIT;
    const currentPage = pages.length;
    return totalPage > currentPage ? currentPage + 1 : null;
  };

  const getPreviousPageParam = (_, __, currentPage) => {
    return currentPage > 1 ? currentPage - 1 : null;
  };

  return useInfiniteQuery({
    queryKey: orderKeys.infinite(),
    queryFn,
    initialPageParam: initPage,
    getNextPageParam,
    getPreviousPageParam,
    enabled: !!initPage
  });
};

export const useGetOrderDetails = _id => {
  const url = `${API_END_POINT}/${_id}`;

  return useQuery({
    queryKey: orderKeys.detail(_id),
    queryFn: () => apiCallV2({ url })
  });
};

export const useGetOrderPackingSlip = () => {
  return useMutation({
    mutationFn: id => {
      const url = `${API_END_POINT}/export-pdf/${id}`;
      return apiCallV2({ url, method: 'get' });
    }
  });
};

export const useCreateOrderLog = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = `${API_END_POINT}/log-actions`;

      return apiCallV2({
        method: 'POST',
        url,
        data,
        signal: controller.signal
      });
    }
  });

  return mutation;
};

export const useGetShippingConfigs = () => {
  const url = `${API_END_POINT}/shipping-configurations`;

  return useQuery({
    queryKey: orderKeys.shippingConfigs(),
    queryFn: () =>
      apiCallV2({
        url
      })
  });
};

export const useGetShipmentRates = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = `${API_END_POINT}/${data._id}/shipment-rates`;
      delete data._id;

      return apiCallV2({
        method: 'POST',
        url,
        data,
        signal: controller.signal
      });
    }
  });

  return mutation;
};

export const useSaveShipment = orderId => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = `${API_END_POINT}/${orderId}/shipments`;

      return apiCallV2({
        method: 'POST',
        url,
        data,
        signal: controller.signal
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.detail(orderId) });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useVoidLabel = orderId => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      const url = `${API_END_POINT}/${orderId}/void-labels`;
      return apiCallV2({
        method: 'POST',
        url
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.detail(orderId) });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useWriteOrderNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data = {}) => {
      const url = `${API_END_POINT}/${data._id}/order-note`;
      return apiCallV2({ url, method: 'PUT', data: { note: data?.note } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });
};

export const useUpdateShippingAddress = orderId => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = `${API_END_POINT}/${orderId}/order-shipping-address`;
      delete data._id;

      return apiCallV2({
        method: 'PUT',
        url,
        data: {
          shipping_address: data
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderKeys.detail(orderId)
      });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useFulfillOrder = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: orderId => {
      const url = `${API_END_POINT}/${orderId}/fulfills`;
      return apiCallV2({
        method: 'POST',
        url
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useSyncTrackingStatus = orderId => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      const url = `${API_END_POINT}/${orderId}/sync-shippos`;
      return apiCallV2({
        method: 'POST',
        url
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useMarkAsDelivered = orderId => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      const url = `${API_END_POINT}/${orderId}/complete-shipments`;
      return apiCallV2({
        method: 'POST',
        url
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });

  return mutation;
};

export const useCancelOrder = id => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reason => {
      const url = `admin/orders/${id}/cancel`;
      return apiCallV2({
        url,
        method: 'POST',
        data: { cancel_reason: reason }
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });
};

export const useUpdateOrderStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data = {}) => {
      const url = `${API_END_POINT}/${data._id}/order-status`;
      return apiCallV2({
        url,
        method: 'PUT',
        data: { order_status: data?.order_status }
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    }
  });
};

/**
 * Get all orders for seller with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page (required), limit (required), seller_id (required)
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetOrdersSeller = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'orders/get_all_order_admin_seller';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery({
    queryKey: [...key],
    queryFn: () => apiCall(url, body, headers, controller.signal)
  });
};

/**
 * Update order status using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * order_id (required), order_status (required), seller_id (required)
 * @return {Object} The object with data, status, error, etc.
 */

/**
 * Export all orders to Excel
 */
export async function exportOrders(data = {}) {
  const controller = new AbortController();
  const url = 'orders/export_excel';
  const body = {
    ...data
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Export packing slip to PDF
 */
export async function exportPackingSlip(data = {}) {
  const controller = new AbortController();
  const headers = {};
  const url = 'orders/get_order_slip';

  return apiCallV2({ url, data, headers, signal: controller.signal });
}

/**
 * Export invoice to PDF
 */
export async function exportInvoice(data = {}) {
  const controller = new AbortController();
  const url = 'orders/get_order_invoice';
  const headers = {};
  const body = {
    ...data
  };

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Send order status to SMS
 */
export async function sendOrderStatus(data = {}) {
  const controller = new AbortController();
  const url = 'orders/get_order_message';
  const headers = {};
  const body = {
    ...data
  };

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Get all notes for an order
 * @param {object} data - an object contain key-value as filters
 *  order_id (required)
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetNotes = (data = {}, key) => {
  const { user } = useGetLogin();

  const controller = new AbortController();

  const adminURL = 'orders/all_notes';
  const sellerURL = 'orders/all_notes_seller';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery({
    queryKey: [...key],
    queryFn: () =>
      apiCall(
        user?.type ? adminURL : sellerURL,
        body,
        headers,
        controller.signal
      )
  });
};

/**
 * Add new note for order using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * order_id (required), note (required)
 * @return {Object} The object with data, status, error, etc.
 */
export const addNoteOrder = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = 'orders/add_notes';
      const headers = {};
      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

/**
 * Update/delete note for order using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * order_id (required), index (required: `0`, `1`), type (required: `edit`, `del`), note (required for `edit` type)
 * @return {Object} The object with data, status, error, etc.
 */
export const useUpdateNoteOrder = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = 'orders/edit_del_notes';
      const headers = {};
      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

export const useOrderCustomers = (filter = {}) =>
  useQuery({
    queryKey: ['useOrderCustomers', filter],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: 'admin/customers',
        params: filter
      }),
    ...config
  });

export const useUpdateReview = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const url = 'admin/product-reviews/:id'?.replace(':id', data?._id);
      const { _id, customer, tags, status, content, rating } = data;
      return apiCallV2({
        url,
        method: 'put',
        data: {
          tags,
          status,
          content,
          rating
        }
      });
    }
  });
};

export const useExportOrders = (params = {}, key) => {
  const url = '/admin/orders/exports';
  return useQuery({
    queryKey: ['useExportOrders', params],
    enabled: false,
    queryFn: () =>
      apiCallV2({
        url,
        params
      })
  });
};
