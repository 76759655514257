import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Offcanvas } from 'react-bootstrap';
import { FilterForm } from './FilterForm';
import { useURLParams } from 'hooks/useURLParams';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const FilterCanvasLayout = ({ show, setShow, ...rest }) => {
  const { t } = useTranslation();

  const { params, handleParamsChange } = useURLParams();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      status: params.status ?? 'active',
      date_range: {
        from: params?.['date_range[from]']
          ? new Date(params['date_range[from]'] * 1000)
          : null,
        to: params?.['date_range[to]']
          ? new Date(params['date_range[to]'] * 1000)
          : null
      },
      title_type: params.title_type
        ? {
            value: params.title_type,
            label: t(`customer.${params.title_type}`)
          }
        : null
    }
  });
  const handleClose = () => setShow(false);

  const onReset = () => {
    const updatedInputData = Object.keys(watch()).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {});

    updatedInputData['date_range[to]'] = undefined;
    updatedInputData['date_range[from]'] = undefined;

    handleParamsChange(updatedInputData);
    reset();
  };

  const onSave = inputData => {
    const updatedInputData = Object.keys(inputData).reduce((acc, key) => {
      acc[key] = inputData[key] !== null ? inputData[key] : undefined;
      return acc;
    }, {});

    updatedInputData['date_range[to]'] = updatedInputData?.date_range?.to
      ? dayjs(updatedInputData?.date_range?.to).endOf('day').unix()
      : undefined;
    updatedInputData['date_range[from]'] = updatedInputData?.date_range?.from
      ? dayjs(updatedInputData?.date_range?.from).unix()
      : undefined;
    updatedInputData.title_type = updatedInputData?.title_type?.value;
    updatedInputData.status = updatedInputData?.status?.value;
    updatedInputData.is_referral = !!updatedInputData?.is_referral || undefined;

    delete updatedInputData?.date_range;

    handleParamsChange(updatedInputData);
    setShow(false);
  };

  return (
    <Offcanvas show={show} onHide={handleClose} {...rest}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-0">
          {t('filter.filters')}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-0">
        <FilterForm
          {...{
            handleSubmit,
            control,
            onSave,
            register,
            errors,
            onReset,
            watch,
            setValue
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

FilterCanvasLayout.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};
