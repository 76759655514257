import { useQuery, useMutation } from '@tanstack/react-query';
import { apiCall } from './ApiCall';
import { dataFetchingQueryConfig } from 'config';

const config = dataFetchingQueryConfig;

/**
 * ! ROUTE NOT WORKING AT THE MOMENT
 * Retrievers a list of notifications with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page, limit, user_id
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */

export const useGetNotifications = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'notification/get_all_notification';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery(
    key,
    () => apiCall(url, body, headers, controller.signal),
    config
  );
};

/**
 * POST a notification to role with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page, limit, zip_code, state
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */

export const useCreateNotification = () => {
  const controller = new AbortController();

  const mutation = useMutation((data = {}) => {
    const url = 'notification/notification_send';
    const headers = {};
    const body = {
      ...data,
      role: 'customer'
    };

    return apiCall(url, body, headers, controller.signal);
  });

  return mutation;
};

/**
 * Only for admin
 * Retrievers a list of notifications of new sellers
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */

export const useGetNewSellersNotifications = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'selleradmin/get_seller_notification';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery({
    queryKey: key,
    queryFn: () => apiCall(url, body, headers, controller.signal),
    config
  });
};

/**
 * POST a notification to a specific user with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page, limit, to_user_id, to_user_ref, text
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */

// export const useCreateNotificationOneUser = (data = {}) => {
//   const controller = new AbortController();
//   const mutation = useMutation(() => {
//     const url = 'notification/add_notification_admin';
//     const headers = {};
//     const body = {
//       ...data,
//       role: 'customer'
//     };

//     return apiCall(url, body, headers, controller.signal);
//   });

//   return mutation;
// };
