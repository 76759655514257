// ------------------- Query string ------------------- //
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_QUERY_STRING = `limit=25&page=1`;

// ------------------- Validation Rules ------------------- //
// Name
export const FULL_NAME_MIN_LENGTH = 1;
export const FULL_NAME_PATTERN = /.*[a-zA-Z].*/; // Contain at least 1 letter
export const FULL_NAME_PATTERN_ERROR_MESSAGE =
  'Name must contain at least 1 letter!';

export const FIRST_NAME_PATTERN_ERROR_MESSAGE =
  'First name must contain at least 1 letter!';
export const FIRST_NAME_REQUIRE_ERROR_MESSAGE = 'First name is required!';
export const LAST_NAME_PATTERN_ERROR_MESSAGE =
  'Last name must contain at least 1 letter!';
export const LAST_NAME_REQUIRE_ERROR_MESSAGE = 'Last name is required!';

export const STORE_NAME_MIN_LENGTH = 1;
export const STORE_NAME_PATTERN = /.*[a-zA-Z].*/; // Contain at least 1 letter
export const STORE_NAME_PATTERN_ERROR_MESSAGE =
  'Store name must contain at least 1 letter!';
export const STORE_NAME_REQUIRED_ERROR_MESSAGE = 'Store name is required!';

// Phone number
export const PHONE_NO_PATTERN = /^1?\(?(\d{3})\)?[-\s]?(\d{3})[-\s]?(\d{4})$/;
export const PHONE_NO_ERROR_MESSAGE = 'Please enter phone number!';
export const PHONE_NO_REQUIRE_ERROR_MESSAGE = 'Phone number is required!';

// Email
export const EMAIL_INVALID_ERROR_MESSAGE = 'Email is invalid!';
export const EMAIL_REQUIRE_ERROR_MESSAGE = 'Email is required!';

// Address
export const ADRESS_PATTERN = /^(?=.*[a-zA-Z])(?=.*\d)(.{1,})$/; //
export const ADDRESS_PATTERN_ERROR_MESSAGE =
  'Address must have at least one letter and one number!';
export const ADDRESS_REQUIRE_ERROR_MESSAGE = 'Address is required!';

// Password
export const PASSWORD_PATTERN =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-={}\]|;:'",.<>?]).{8,}$/; // Password must contain at least one number, one uppercase and one lowercase letter,
export const PASSWORD_PATTERN_ERROR_MESSAGE = 'Password must be stronger!';
export const PASSWORD_REQUIRE_ERROR_MESSAGE = 'Password is required!';
export const PASSWORD_OLD_ERROR_MESSAGE = 'You must specify your old password!';
export const PASSWORD_INVALID_ERROR_MESSAGE = 'Password is invalid!';
export const CONFIRM_PASSWORD_ERROR_MESSAGE = 'Password does not match!';
export const PASSWORD_SIMPLE_PATTERN = /^[A-z0-9]{6}$/; // OTP must be 4 digits

// OTP
export const OPT_PATTERN = /^[0-9]{4}$/; // OTP must be 4 digits
export const OPT_PATTERN_ERROR_MESSAGE = 'The code must have 4 digits!';
export const OTP_REQUIRE_ERROR_MESSAGE = 'Verification code is required!';

// Title
export const TITLE_REQUIRE_ERROR_MESSAGE = 'Title is required!';
export const DESCRIPTION_REQUIRE_ERROR_MESSAGE = 'Description is required!';

// DATE TIME
export const DEFAULT_DATE_TIME_FORMAT = 'MMM D, YYYY [at] h:mm A';
export const DATE_TIME_FORMAT_VI = 'DD MMM, YYYY [lúc] HH:mm';
export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';

export const DEPARTMENT_REQUIRE_ERROR_MESSAGE = 'Department is required!';

// App Roles
export const APP_ROLES = Object.freeze({
  super_admin: 'super_admin',
  seller: 'seller'
});

export const DATA_STATUS_ENUM = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  CLOSED: 'closed'
};

export const DATA_STATUS = t => ({
  [DATA_STATUS_ENUM.ACTIVE]: t('general.active'),
  [DATA_STATUS_ENUM.DRAFT]: t('general.draft'),
  [DATA_STATUS_ENUM.CLOSED]: t('general.closed')
});
