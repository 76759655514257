import { lazy } from 'react';
import Loadable from 'components/common/Loadable';
import { PagePlaceholder } from 'components/common/PlaceHolder';
import PackagesPage from 'pages/packages';
import AddDraftCartsPage from '../pages/add-draft-carts';
import DraftCartDetailsPage from '../pages/add-draft-carts/DraftCartDetails';

const AccountPage = Loadable(lazy(() => import('pages/account')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

const Notifications = Loadable(lazy(() => import('pages/notifications')));
const OrdersPage = Loadable(lazy(() => import('pages/orders')));
const OrderDetailsPage = Loadable(
  lazy(() => import('pages/orders/order-details'))
);
const FulfillOrderPage = Loadable(
  lazy(() => import('pages/orders/fulfill-order'))
);
const DraftCartsPage = Loadable(lazy(() => import('pages/draft-carts')));
const Carts = Loadable(lazy(() => import('pages/carts')));
const AddCartPage = Loadable(lazy(() => import('pages/carts/add-cart')));
const CartDetailsPage = Loadable(
  lazy(() => import('pages/carts/cart-details'))
);
const EditCartPage = Loadable(lazy(() => import('pages/carts/edit-cart')));
const ProductsPage = Loadable(lazy(() => import('pages/products')));
const ProductDetailsPage = Loadable(
  lazy(() => import('pages/products/product-details'))
);
const AddProduct = Loadable(lazy(() => import('pages/products/add-product')));
const SimilarProductsPage = Loadable(
  lazy(() => import('pages/products/similar-products'))
);
const ProductReviewsPage = Loadable(
  lazy(() => import('pages/products/reviews'))
);
const CategoriesPage = Loadable(lazy(() => import('pages/categories')));
const SubcategoriesPage = Loadable(
  lazy(() => import('pages/categories/subcategories'))
);
const BrandsPage = Loadable(lazy(() => import('pages/brands')));
const BrandsProductsPage = Loadable(
  lazy(() => import('pages/brands/brands-products'))
);
const Coupons = Loadable(lazy(() => import('pages/coupons')));
const Promotions = Loadable(lazy(() => import('pages/promotions')));
const CouponDetailsPage = Loadable(
  lazy(() => import('pages/coupons/coupons-details'))
);
const PromotionListing = Loadable(
  lazy(() => import('pages/promotion-listing'))
);
const CustomersPage = Loadable(lazy(() => import('pages/customers')));
const UserDetailsPage = Loadable(
  lazy(() => import('pages/customers/customer-details'))
);
const SellersPage = Loadable(lazy(() => import('pages/sellers')));
const SellerDetailsPage = Loadable(
  lazy(() => import('pages/sellers/seller-details'))
);
const JobPostsPage = Loadable(lazy(() => import('pages/jobs')));
const TechPostsPage = Loadable(lazy(() => import('pages/techs')));
const SalonPostsPage = Loadable(lazy(() => import('pages/salons')));
const SkillsPage = Loadable(lazy(() => import('pages/skills')));
const EventsPage = Loadable(lazy(() => import('pages/events')));
const EventUsersPage = Loadable(lazy(() => import('pages/events/event-users')));
const BalancePage = Loadable(lazy(() => import('pages/balance')));
const PayoutsPage = Loadable(lazy(() => import('pages/payouts')));
const PayoutDetailsPage = Loadable(
  lazy(() => import('pages/payouts/payout-details'))
);
const StaffPage = Loadable(lazy(() => import('pages/staff')));
const StaffDetailsPage = Loadable(
  lazy(() => import('pages/staff/staff-details'))
);
const ShippingPage = Loadable(lazy(() => import('pages/shipping')));
const TaxesPage = Loadable(lazy(() => import('pages/taxes')));
const FAQsPage = Loadable(lazy(() => import('pages/faqs')));
const CreatePromotion = Loadable(lazy(() => import('pages/promotion-create')));
const PromotionAddProduct = Loadable(
  lazy(() => import('pages/promotion-add-product'))
);
const MarketplaceInformationPage = Loadable(
  lazy(() => import('pages/information')),
  <PagePlaceholder />
);
const HelpArticlesPage = Loadable(
  lazy(() => import('pages/help-articles')),
  <PagePlaceholder />
);
const AddMarketplaceInformationPage = Loadable(
  lazy(() => import('pages/information/add-information'))
);
const StorePage = Loadable(lazy(() => import('pages/store')));
const WareHousesPage = Loadable(lazy(() => import('pages/warehouses')));
const ReviewList = Loadable(lazy(() => import('pages/reviews')));
const ExportImportProducts = Loadable(
  lazy(() =>
    import('pages/products/export-import-products/ExportImportProducts')
  )
);

const accessRoutes = [
  {
    access: 'account',
    path: '/account',
    isSeller: true,
    isAdmin: true,
    component: AccountPage
  },
  {
    access: 'dashboard',
    path: '/',
    isSeller: true,
    isAdmin: true,
    component: Dashboard
  },
  {
    access: 'dashboard',
    path: '/dashboard',
    isSeller: true,
    isAdmin: true,
    component: Dashboard
  },
  {
    access: 'notifications',
    path: '/notifications',
    isSeller: false,
    isAdmin: true,
    component: Notifications
  },
  {
    access: 'orders',
    path: '/orders',
    isSeller: true,
    isAdmin: true,
    component: OrdersPage
  },
  {
    access: 'orders',
    path: '/orders/:orderId',
    isSeller: true,
    isAdmin: true,
    component: OrderDetailsPage
  },
  {
    access: 'orders',
    path: '/orders/:orderId/fulfill-order',
    isSeller: true,
    isAdmin: true,
    component: FulfillOrderPage
  },
  {
    access: 'draft-carts',
    path: '/draft-carts',
    isSeller: true,
    isAdmin: true,
    component: DraftCartsPage
  },
  {
    access: 'add-draft-carts',
    path: '/draft-carts/add',
    isSeller: true,
    isAdmin: true,
    component: AddDraftCartsPage
  },
  {
    access: 'draft-carts-details',
    path: '/draft-carts/:id',
    isSeller: true,
    isAdmin: true,
    component: DraftCartDetailsPage
  },
  {
    access: 'carts',
    path: '/carts',
    isSeller: false,
    isAdmin: true,
    component: Carts
  },
  {
    access: 'carts',
    path: '/carts/add',
    isSeller: false,
    isAdmin: true,
    component: AddCartPage
  },
  {
    access: 'carts',
    path: '/carts/:cartId',
    isSeller: false,
    isAdmin: true,
    component: CartDetailsPage
  },
  {
    access: 'carts',
    path: '/carts/:cartId/edit',
    isSeller: false,
    isAdmin: true,
    component: EditCartPage
  },
  {
    access: 'balance',
    path: '/balance',
    isSeller: false,
    isAdmin: true,
    component: BalancePage
  },
  {
    access: 'payouts',
    path: '/payouts',
    isSeller: true,
    isAdmin: false,
    component: PayoutsPage
  },
  {
    access: 'payouts',
    path: '/payouts/:sellerId/orders',
    isSeller: false,
    isAdmin: true,
    component: PayoutsPage
  },
  {
    access: 'payouts',
    path: '/payouts/:sellerId/orders/:orderId/payouts',
    isSeller: true,
    isAdmin: true,
    component: PayoutDetailsPage
  },
  {
    access: 'products',
    path: '/products',
    isSeller: true,
    isAdmin: true,
    component: ProductsPage
  },
  {
    access: 'products',
    path: '/products/:productId',
    isSeller: true,
    isAdmin: true,
    component: ProductDetailsPage
  },
  {
    access: 'products',
    path: '/products/:productId/duplicate',
    isSeller: true,
    isAdmin: true,
    component: ProductDetailsPage
  },
  {
    access: 'products',
    path: '/products/add',
    isSeller: true,
    isAdmin: true,
    component: AddProduct
  },
  {
    access: 'products',
    path: '/products/:productId/similar',
    isSeller: true,
    isAdmin: true,
    component: SimilarProductsPage
  },
  {
    access: 'products',
    path: '/products/:productId/reviews',
    isSeller: true,
    isAdmin: true,
    component: ProductReviewsPage
  },
  {
    access: 'products',
    path: '/products/export-import',
    isSeller: true,
    isAdmin: true,
    component: ExportImportProducts
  },
  {
    access: 'reviews',
    path: '/reviews',
    isSeller: true,
    isAdmin: true,
    component: ReviewList
  },
  {
    access: 'categories',
    path: '/categories',
    isSeller: false,
    isAdmin: true,
    component: CategoriesPage
  },
  {
    access: 'categories',
    path: '/categories/:categoryId',
    isSeller: false,
    isAdmin: true,
    component: SubcategoriesPage
  },
  {
    access: 'categories',
    path: '/categories/:categoryId/subcategories/:subcategoryId',
    isSeller: false,
    isAdmin: true,
    component: ProductsPage
  },
  {
    access: 'brands',
    path: '/brands',
    isSeller: false,
    isAdmin: true,
    component: BrandsPage
  },
  {
    access: 'brands',
    path: '/brands/:brandId',
    isSeller: false,
    isAdmin: true,
    component: ProductsPage
  },
  {
    access: 'coupons',
    path: '/coupons',
    isSeller: true,
    isAdmin: true,
    component: Coupons
  },
  {
    access: 'coupons',
    path: '/coupons/:couponId',
    isSeller: true,
    isAdmin: true,
    component: CouponDetailsPage
  },
  {
    access: 'coupons',
    path: '/coupons/:couponId/duplicate',
    isSeller: true,
    isAdmin: true,
    component: CouponDetailsPage
  },
  {
    access: 'coupons',
    path: '/coupons/add',
    isSeller: true,
    isAdmin: true,
    component: CouponDetailsPage
  },
  {
    access: 'customers',
    path: '/customers',
    isSeller: false,
    isAdmin: true,
    component: CustomersPage
  },
  {
    access: 'customers',
    path: '/customers/:userId',
    isSeller: false,
    isAdmin: true,
    component: UserDetailsPage
  },
  {
    access: 'sellers',
    path: '/sellers',
    isSeller: false,
    isAdmin: true,
    component: SellersPage
  },
  {
    access: 'sellers',
    path: '/sellers/:sellerId',
    isSeller: false,
    isAdmin: true,
    component: SellerDetailsPage
  },
  {
    access: 'jobs',
    path: '/jobs',
    isSeller: false,
    isAdmin: true,
    component: JobPostsPage
  },
  {
    access: 'techs',
    path: '/techs',
    isSeller: false,
    isAdmin: true,
    component: TechPostsPage
  },
  {
    access: 'salons',
    path: '/salons',
    isSeller: false,
    isAdmin: true,
    component: SalonPostsPage
  },
  {
    access: 'skills',
    path: '/skills',
    isSeller: false,
    isAdmin: true,
    component: SkillsPage
  },
  {
    access: 'events',
    path: '/events',
    isSeller: false,
    isAdmin: true,
    component: EventsPage
  },
  {
    access: 'events',
    path: '/events/:eventId',
    isSeller: false,
    isAdmin: true,
    component: EventUsersPage
  },
  {
    access: 'staff',
    path: '/staff',
    isSeller: false,
    isAdmin: true,
    component: StaffPage
  },
  {
    access: 'staff',
    path: '/staff/:staffId',
    isSeller: false,
    isAdmin: true,
    component: StaffDetailsPage
  },
  {
    access: 'shipping',
    path: '/shipping',
    isSeller: false,
    isAdmin: true,
    component: ShippingPage
  },
  {
    access: 'faqs',
    path: '/faqs',
    isSeller: false,
    isAdmin: true,
    component: FAQsPage
  },
  {
    access: 'taxes',
    path: '/taxes',
    isSeller: false,
    isAdmin: true,
    component: TaxesPage
  },
  {
    access: 'promotions',
    path: '/promotions',
    isSeller: false,
    isAdmin: true,
    component: Promotions
  },
  {
    access: 'flash-sales/add',
    path: '/flash-sales/add',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'for_sale', isAdd: true },
    component: CreatePromotion
  },
  {
    access: 'flash-sales/:id',
    path: '/flash-sales/:id',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'for_sale', isAdd: false },
    component: CreatePromotion
  },
  {
    access: 'flash-sales',
    path: '/flash-sales',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'for_sale' },
    component: PromotionListing
  },
  {
    access: 'banners',
    path: '/banners',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'banner' },
    component: PromotionListing
  },
  {
    access: 'banners/add',
    path: '/banners/add',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'banner', isAdd: true },
    component: CreatePromotion
  },
  {
    access: 'banners/:id',
    path: '/banners/:id',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'banner', isAdd: false },
    component: CreatePromotion
  },
  {
    access: 'flash-sales/:id/add-products',
    path: '/flash-sales/:id/add-products',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'for_sale' },
    component: PromotionAddProduct
  },
  {
    access: 'banners/:id/add-products',
    path: '/banners/:id/add-products',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'banner' },
    component: PromotionAddProduct
  },
  {
    access: 'information',
    path: '/information',
    isSeller: false,
    isAdmin: true,
    component: MarketplaceInformationPage
  },
  {
    access: 'information',
    path: '/information/add',
    isSeller: false,
    isAdmin: true,
    component: AddMarketplaceInformationPage
  },
  {
    access: 'help-articles',
    path: '/help-articles/add',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'articles' },
    component: AddMarketplaceInformationPage
  },
  {
    access: 'information',
    path: '/information/:informationId',
    isSeller: false,
    isAdmin: true,
    component: AddMarketplaceInformationPage
  },
  {
    access: 'help-articles',
    path: '/help-articles/:informationId',
    isSeller: false,
    isAdmin: true,
    defaultProps: { type: 'articles' },
    component: AddMarketplaceInformationPage
  },
  {
    access: 'help-articles',
    path: '/help-articles',
    isSeller: false,
    isAdmin: true,
    component: HelpArticlesPage
  },
  {
    access: 'store',
    path: '/store',
    isSeller: true,
    isAdmin: false,
    component: StorePage
  },
  {
    access: 'warehouse',
    path: '/warehouse',
    isSeller: true,
    isAdmin: true,
    component: WareHousesPage
  },
  {
    access: 'packages',
    path: '/packages',
    isSeller: true,
    isAdmin: true,
    component: PackagesPage
  }
];

export default accessRoutes;
