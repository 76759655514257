export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
};

export const marketplaceRoutes = {
  label: 'Marketplace',
  children: [
    {
      name: 'Orders',
      to: '/orders',
      active: true,
      icon: 'shopping-cart'
    },
    {
      name: 'Products',
      to: '/products',
      active: true,
      icon: 'tag'
    },
    {
      name: 'Reviews',
      to: '/reviews',
      icon: 'star',
      active: true
    },
    {
      name: 'Coupons',
      to: '/coupons',
      icon: 'percentage',
      active: true
    }
    // {
    //   name: 'Finances',
    //   active: true,
    //   icon: 'dollar-sign',
    //   children: [
    //     {
    //       name: 'Balance',
    //       to: '/balance',
    //       active: true
    //     },
    //     {
    //       name: 'Transactions',
    //       to: '/transactions',
    //       active: false
    //     },
    //     {
    //       name: 'Payouts',
    //       to: '/payouts',
    //       active: false
    //     }
    //   ]
    // }
  ]
};

export const settingsRoutes = {
  label: 'Settings',
  labelDisable: false,
  children: [
    {
      name: 'Account',
      to: '/account',
      active: true,
      icon: 'user-circle'
    },
    {
      name: 'Store details',
      to: '/store',
      active: true,
      icon: 'store'
    },
    {
      name: 'Warehouse',
      to: '/warehouse',
      active: true,
      icon: 'warehouse'
    },
    {
      name: 'Packages',
      to: '/packages',
      active: true,
      icon: 'box'
    }
  ]
};
export default [dashboardRoutes, marketplaceRoutes, settingsRoutes];
