import 'react-phone-number-input/style.css';
import React from 'react';
import classNames from 'classnames';
import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';

const FlagPhoneInput = ({ errors, className, name, ...rest }) => {
  return (
    <PhoneInputWithCountrySelect
      {...rest}
      rules={{ required: false }}
      name={name}
      defaultCountry="US"
      countryOptionsOrder={['US', 'CA', 'AU', 'VN', '|', '...']}
      international
      countryCallingCodeEditable={false}
      className={classNames(
        className,
        errors?.[name] && 'PhoneInput--invalid border-danger',
        'p-1 ps-3',
        'flex align-items-center',
        'transition-all duration-75 ease-in-out',
        'rounded border'
      )}
      numberInputProps={{
        className: classNames(
          'w-100 ps-1 py-0 border-0 shadow-none form-control',
          errors?.[name] && 'is-invalid'
        ),
        style: { outline: 'none' }
      }}
    />
  );
};

export default FlagPhoneInput;
