import { useMutation, useQuery } from '@tanstack/react-query';
import { apiCall, apiCallV2 } from './ApiCall';
import { useQueryConfig } from 'config';

/**
 * Retrievers a list of users with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page, limit, user_type
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetUsers = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'users/get_all_users';
  const headers = {};
  const body = {
    ...data,
    user_type: 'customer'
  };

  return useQuery({
    queryKey: [...key],
    queryFn: () => apiCall(url, body, headers, controller.signal)
  });
};

/**
 * Retrievers a list of users with optional filter and pagination from web app
 * @param {object} data - an object contain key-value as filters
 *  page, limit, user_type, sortData, search_by...
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetUsersApp = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'users/get_all_users_app';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery({
    queryKey: [key, data.page],
    queryFn: () => apiCall(url, body, headers, controller.signal)
  });
};

/**
 * Retrievers a list of users with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  user_id, role
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useCustomerDetails = id => {
  const url = 'admin/customers/' + id;

  return useQuery({
    queryKey: ['useCustomerDetails', id],
    queryFn: () => apiCallV2({ url, method: 'get' })
  });
};

/**
 * Create User with required params and return user's details with new password f_name@nailzy
 * @param {object} data - an object contain key-value as params includes
 *  f_name, l_name, email, phone
 * @returns {<Object>} - An object contain data, error, isLoading, statusm...
 */
export const useCreateUserByAdmin = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'auth/register_from_admin';
      const headers = {};

      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

/**
 * Update User Details with required params
 * @param {object} data - an object contain key-value as params
 *  user_id (required), ...other
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useUpdateUserByAdmin = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'users/user_update_by_admin';
      const headers = {};

      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

/**
 * Retrievers a list of user's notes with optional filter and pagination
 * @param {object} data - an object contain key-value as filters  user_id, role
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetNotesForUser = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'users/all_notes';
  const headers = {};
  const body = {
    ...data
  };

  return useQuery({
    queryKey: [key, data.page],
    queryFn: () => apiCall(url, body, headers, controller.signal)
  });
};

/**
 * Create a Note for User with required params
 * @param {object} data - an object contain key-value as params
 *  note (required), user_id (required), role, ...other
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useCreateNoteForUser = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'users/add_notes';
      const headers = {};

      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

/**
 * Update Note of User with required params
 * @param {object} data - an object contain key-value as params
 *  note (required), user_id (required), role, index, type (edit/del)
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useUpdateNoteForUser = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'users/edit_del_notes';
      const headers = {};

      const body = {
        ...data
      };

      return apiCall(url, body, headers, controller.signal);
    }
  });

  return mutation;
};

// Export users to Excel
export async function exportUsers(data = {}) {
  const controller = new AbortController();
  const url = 'users/export_excel';
  const body = {
    ...data,
    user_type: 'customer'
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}
