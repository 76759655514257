import store from 'reduxStore';
import { apiCallV2 } from './ApiCall';
import { updateAppImages } from 'actions/images';
import axios from 'axios';
import { useState } from 'react';

export const syncImagePaths = async imageIds => {
  let ids = imageIds.reduce((prev, curr, index) => {
    let value = curr
      ?.split?.('/')
      ?.pop()
      ?.replace(/[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-="']/g, '')
      ?.substring(0, 24);

    if (value && value?.length === 24) {
      prev.push(value);
    }
    return prev;
  }, []);
  if (!ids?.length) return;
  const url = 'admin/media/generate-download-urls';
  const res = await apiCallV2({
    method: 'POST',
    url: url,
    data: { ids }
  });
  if (res?.data?.length) {
    let imagesPaths = {};
    const array = res?.data?.map(x => {
      if (x?._id && x?.url) {
        imagesPaths[x?._id] = x?.url;
      }
    });
    store.dispatch(updateAppImages(imagesPaths));
  }
  return res;
};

export const useUploadMedia = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const doUpload = async ({ image, type = 'default' }) => {
    setLoading(true);
    setSuccess(false);
    setError(undefined);
    try {
      const { data: dataGen } = await apiCallV2({
        method: 'POST',
        url: 'admin/media/generate-upload-urls',
        data: {
          contentLength: image?.size + 1000000,
          contentType: image?.mime,
          name: image?.filename || image?.name || image?.path,
          isPublic: false,
          subFolder: type
        }
      });
      const { signedUrl, fields, id } = dataGen || {};

      if (!signedUrl) {
        throw new Error('signedUrl is not found');
      }

      let data = new FormData();
      Object.keys(fields).forEach(key => data.append(key, fields[key]));
      const uploadImage = {
        uri: image?.path,
        name: image?.filename || image?.name || image?.path,
        type: image?.mime
      };
      if (image?.blob) {
        let fileName = `${image?.name}`;
        let file = new File([image?.blob], fileName);
        data.append('file', file, fileName);
      } else {
        data.append('file', uploadImage);
      }

      const resS3 = await axios({
        method: 'POST',
        url: signedUrl,
        data,
        headers: {
          ['Content-Type']: 'multipart/form-data'
        }
      });

      if (resS3) {
        const { data: dataSync, status } = await apiCallV2({
          url: 'admin/media/:id/sync-status'.replace(':id', id),
          method: 'PUT'
        });

        if (status) {
          setSuccess(true);
          return id;
        }
      } else {
        throw new Error('Upload failed');
      }
    } catch (_error) {
      console.log('_error', _error);
      setError(_error);
    } finally {
      setLoading(false);
    }
  };

  return {
    doUpload,
    loading,
    success,
    error
  };
};
