import SelectInput from 'components/common/inputs/SelectInput';
import StatusInput from 'components/common/inputs/StatusInput';
import { CustomerTitleTypeOptions } from 'constants/customers';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { default as ReactDatePicker } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const FilterForm = ({
  handleSubmit,
  onReset,
  onSave,
  setValue,
  watch,
  control
}) => {
  const { t } = useTranslation();

  const onDatesChange = dates => {
    const [start, end] = dates;
    setValue('date_range[from]', start);
    setValue('date_range[to]', end);
  };

  const startDate = watch('date_range[from]');
  const endDate = watch('date_range[to]');
  const is_referral = watch('is_referral');

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <Form.Group className="mb-3" controlId={'status'}>
        <Form.Label>{t('general.status')}</Form.Label>
        <Controller
          name={'status'}
          control={control}
          render={({ field }) => {
            return (
              <StatusInput
                {...field}
                isClearable
                placeholder={t('orders.select_status')}
              />
            );
          }}
        />
      </Form.Group>

      <Form.Group controlId="joined-date" className="mb-3">
        <Form.Label>{t('filter.joined_date_range')}</Form.Label>
        <ReactDatePicker
          fixedHeight
          className="form-control"
          timeIntervals={1}
          formatWeekDay={day => day.slice(0, 3)}
          startDate={startDate}
          selected={startDate}
          endDate={endDate}
          onChange={onDatesChange}
          selectsRange
          placeholderText={t('general.select_date_range')}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId={'title_type'}>
        <Form.Label>{t('filter.role')}</Form.Label>
        <Controller
          name={'title_type'}
          control={control}
          render={({ field }) => {
            return (
              <SelectInput
                {...field}
                options={CustomerTitleTypeOptions?.map(x => ({
                  ...x,
                  label: t(x.label)
                }))}
                isClearable
                placeholder={t('general.select_role')}
              />
            );
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={'title_type'}>
        <Form.Label>{t('customer.referrals')}</Form.Label>
        <Form.Check
          type="switch"
          id="defaultSwitch"
          onChange={e => {
            setValue('is_referral', e?.target?.checked);
          }}
          checked={!!is_referral}
        />
        {/* <Controller
          name={'title_type'}
          control={control}
          render={({ field }) => {
            return (
              <SelectInput
                {...field}
                options={CustomerTitleTypeOptions}
                isClearable
                placeholder={t('general.select_role')}
              />
            );
          }}
        /> */}
      </Form.Group>

      <div className="d-flex justify-content-end gap-3">
        <Button variant="falcon-default" onClick={onReset}>
          {t('general.reset')}
        </Button>
        <Button type="submit" variant="primary">
          {t('general.save')}
        </Button>
      </div>
    </Form>
  );
};
