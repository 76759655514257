import classNames from 'classnames';
import Section from 'components/common/Section';
import i18next from 'i18next';
import { Card, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logo.png';

const FallBackComponent = () => {
  const { t } = useTranslation();
  const toggleLanguage = () => {
    const currentLanguage = localStorage.getItem('languages');
    const newLanguage = currentLanguage === 'en' ? 'vi' : 'en';
    localStorage.setItem('languages', newLanguage);
    i18next.changeLanguage(newLanguage);
  };

  return (
    <>
      <Navbar className="navbar-glass bg-white navbar-top m-0 fw-normal">
        <a href="/" className={classNames('text-decoration-none')}>
          <div className={classNames('d-flex', {})}>
            <img className="me-2" src={logo} alt="Logo" width={100} />
          </div>
        </a>
        <Nav className="ms-auto fs--1"></Nav>
      </Navbar>

      <div className="container-fluid">
        <div className="content">
          <Section className="pt-6">
            <Card className="text-center h-100">
              <Card.Body className="p-5">
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                  {t('error.error_500_message')}
                </p>
                <hr />
                <p>{t('error.error_500_content')}</p>
              </Card.Body>
            </Card>
          </Section>
        </div>
      </div>
    </>
  );
};

export default FallBackComponent;
