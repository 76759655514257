import React from 'react';
import './styles.scss';
const IconFA = ({
  className,
  color,
  type = 'solid',
  onClick,
  bgColor = 'transparent',
  iconStyle = {},
  iconClassName = ''
}) => {
  return (
    <span
      onClick={onClick}
      className={`${onClick ? 'icon-fa-button' : ''}`}
      style={{ backgroundColor: bgColor }}
    >
      <i
        className={`fa-${type} fa-${className?.replace(
          'fa-',
          ''
        )} ${iconClassName}`}
        style={{ color: color || 'black', ...iconStyle }}
      ></i>
    </span>
  );
};
export default IconFA;
