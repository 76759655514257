import { useQueryClient } from '@tanstack/react-query';
import { CloseButton } from 'components/common/Toast';
import is from 'is_js';
import React, { lazy, useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import accessRoutes from 'routes/accessRoutes';
import { Loading } from './Loading';

import AuthSimpleLayout from './AuthSimpleLayout';
import ErrorLayout from './ErrorLayout';
import MainLayout from './MainLayout';

import Error403 from 'components/errors/Error403';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import { apiCallV2 } from 'apis/ApiCall';
import Loadable from 'components/common/Loadable';
import { APP_ROLES } from 'constants/constants';
import { useAuth } from 'contexts/AuthContext';
import AppContext from 'contexts/Context';
import EmailLinkLogin from 'pages/auth/v2/EmailLinkLogin';
import { Button } from 'react-bootstrap';

const AdminLogin = Loadable(lazy(() => import('pages/auth/AdminLogin')));
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const Logout = Loadable(lazy(() => import('pages/auth/Logout')));
const RegisterWizard = Loadable(
  lazy(() => import('pages/auth/RegisterWizard'))
);
const ForgotPasswordWizard = Loadable(
  lazy(() => import('pages/auth/ForgotPasswordWizard'))
);

const Layout = () => {
  const { user, logout } = useAuth();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  // Clear caches if logout
  useEffect(() => {
    if (pathname === '/logout') {
      logout();
      queryClient.cancelQueries();
      queryClient.clear();
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/403" element={<Error403 />} />
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route element={<AuthSimpleLayout />}>
          <Route path="/login-with-link" element={<EmailLinkLogin />} />
          <Route path="/3862" element={<AdminLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<RegisterWizard />} />
          <Route path="/forgot-password" element={<ForgotPasswordWizard />} />
        </Route>

        {/* //--- MainLayout Starts  */}
        <Route element={<MainLayout />}>
          {user ? (
            <>
              {user?.role === APP_ROLES.super_admin && (
                // Super admin
                <React.Fragment>
                  {accessRoutes.map(
                    route =>
                      route.isAdmin && (
                        <Route
                          key={`${route.access}--${route.path}`}
                          path={route.path}
                          element={<route.component {...route?.defaultProps} />}
                        />
                      )
                  )}
                </React.Fragment>
              )}

              {user?.role === APP_ROLES.seller && (
                // seller
                <React.Fragment>
                  {accessRoutes.map(
                    route =>
                      route.isSeller && (
                        <Route
                          key={`${route.access}--${route.path}`}
                          path={route.path}
                          element={<route.component {...route?.defaultProps} />}
                        />
                      )
                  )}
                </React.Fragment>
              )}
            </>
          ) : (
            <Route path="*" element={<Loading />} />
          )}
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {/* Container for Toastify */}
      <ToastContainer
        closeButton={CloseButton}
        icon={true}
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Layout;
