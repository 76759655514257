import SoftBadge from 'components/common/SoftBadge';
import { OrderStatusEnum, OrderStatusStatuses } from 'constants/orders';
import { t } from 'i18next';

const OrderStatus = ({ status = OrderStatusEnum.CANCELED, className }) => {
  const statusConfig = OrderStatusStatuses?.[status];
  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <SoftBadge pill bg={statusConfig.bg} className={`d-block ${className}`}>
        {t(statusConfig.label)}{' '}
        <i
          className={`fa-solid fa-${statusConfig.icon} badge-soft-${statusConfig.bg}`}
        ></i>
      </SoftBadge>
    </div>
  );
};

export default OrderStatus;
